import axios from 'axios';
import { IRoles, IAttributes, IEditRoles, ICreateRoles } from 'src/models/IRoles';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL } from 'src/configs';

export const getAllRoles = createAsyncThunk(
  'get-all-roles',
  async (queryString: any, thunkAPI) => {
    try {
      const response = await axios.get<IRoles>(`${BASE_URL}/roles`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        params: {
          ...queryString
        }
      })
      return response.data; 
    
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors)
    }
  }
);

export const createRoles = createAsyncThunk(
  'create-roles',
  async (formData:IAttributes, thunkAPI) => {
    try {
      const response = await axios.post<ICreateRoles>(`${BASE_URL}/roles`, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      })
      return response.data;

    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors)
    }
  }
);

export const getSingleRole = createAsyncThunk(
  'get-single-role',
  async (id:string | undefined, thunkAPI) => {
    try {
      const response = await axios.get<IRoles>(`${BASE_URL}/roles/${id}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      })
      return response.data;

    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors)
    }
  }
);

export const editRoles = createAsyncThunk(
  'edit-roles',
  async (formData:IEditRoles, thunkAPI) => {
    const id = formData.id
    // @ts-ignore
    delete formData.id;
    try {
      const response = await axios.put<IEditRoles>(`${BASE_URL}/roles/${id}`, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      })
      return response.data;

    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors)
    }
  }
);

export const deleteRoles = createAsyncThunk(
  'delete-roles',
  async (id:string, thunkAPI) => {
    try {
      const response = await axios.delete<IRoles>(`${BASE_URL}/roles/${id}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      })
      return response.data;

    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors)
    }
  }
);
