import {
  Suspense,
  lazy,
  FC,
  useEffect,
  useState,
  Fragment
} from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./generalStyles.css";
import { ConfigProvider } from 'antd';
import ruRU from 'antd/es/locale/ru_RU';

import { useAppDispatch } from "./shared/hooks/useRedux";
import { getCurrentUser } from "./redux/features/users/userAction";
import { BASE_URL } from "src/configs";
import ErrorBoundary from "./shared/components/ErrorBoundary";
import { Loading } from "./shared/components/Loading";

const SignInPage = lazy(() => import("./layouts/SignInPage"));
const Dashboard = lazy(() => import("./layouts/Dashboard"));

interface IAppProps {
  getCurrentUser?: () => void;
}

const App: FC<IAppProps> = (props): JSX.Element => {
  const [auth, setAuth] = useState<any>({ active: false, path: "" });
  const [requestComplete, setRequestComplete] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useAppDispatch();

  const verifyUser = () => {
    axios({
      url: `${BASE_URL}/users/verify`,
      method: "GET",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setRequestComplete(true);
          dispatch(getCurrentUser());
          setAuth((prev: any) => ({ ...prev, active: true }));
          auth?.path && navigate(auth?.path);
        }
      })
      .catch((error:any) => {
        if (error.response.status === 422) {
          setRequestComplete(true);
          setAuth(false);
          navigate("/login");
        }
        if (error.response.status === 401) {
          setRequestComplete(true);
          setAuth((prev: any) => ({
            ...prev,
            active: false,
            path:
              location?.pathname && location?.pathname !== "login"
                ? `${location?.pathname}${location?.search}`
                : "",
          }));
        }
        if (error.response.status === 403) {
          setRequestComplete(false);
          setAuth((prev: any) => ({
            ...prev,
            active: false,
            path:
              location?.pathname && location?.pathname !== "login"
                ? `${location?.pathname}${location?.search}`
                : "",
          }));
          axios({
            url: `${BASE_URL}/users/refresh`,
            method: "GET",
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
            },
          })
            .then((res) => {
              if (res.status === 200) {
                setRequestComplete(true);
                setAuth((prev: any) => ({ ...prev, active: true }));
                dispatch(getCurrentUser());
                auth?.path && navigate(auth?.path);
              }
            })
            .catch((error:any) => {
              if (error.response.status === 401 || 403) {
                setRequestComplete(true);
                setAuth((prev: any) => ({
                  ...prev,
                  active: false,
                  path:
                    location?.pathname && location?.pathname !== "login"
                      ? `${location?.pathname}${location?.search}`
                      : "",
                }));
              }
            });
        }
      });
  };

  useEffect(() => {
    verifyUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPages = () => {
    if (auth?.active && requestComplete) {
      return <Dashboard />;
    } else if (!auth?.active && requestComplete) {
      return <SignInPage verifyUser={verifyUser} setAuth={setAuth} />;
    } else {
      return <Loading />;
    }
  };

  return (
    <Fragment>
      <ConfigProvider locale={ruRU}>
        {/* @ts-ignore TODO: fix this */}
        <ErrorBoundary {...props} navigate={navigate}>
          <Suspense fallback={<Loading />}>
            {renderPages()}
            <ToastContainer autoClose={1000} />
          </Suspense>
        </ErrorBoundary>
      </ConfigProvider>
    </Fragment>
  );
};

export default App;
