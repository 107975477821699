import axios from "axios";
import { IUsersRole } from "src/models/IUsersRole";
import { IUsersWorkspacePermissions } from "src/models/IUsersWorkspacePermissions";
import { IUsersLayerPermissions } from "src/models/IUsersLayerPermissions";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "src/configs";

export const getUsersRole = createAsyncThunk(
  "get-users-roles",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<IUsersRole>(
        `${BASE_URL}/users/me/role`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const getUsersWorkspacePermissions = createAsyncThunk(
  "get-users-workspace-permissions",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<IUsersWorkspacePermissions>(
        `${BASE_URL}/users/me/workspace-permissions`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(
        err?.response?.data?.errors
      );
    }
  }
);

export const getUsersLayerPermissions = createAsyncThunk(
  "get-users-layer-permissions",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<IUsersLayerPermissions>(
        `${BASE_URL}/users/me/layer-permissions`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);
