import { Modal } from "antd";

const handleReduxError = (payload:any) => {

  if (payload?.length) {
    Modal.error({
      title: payload?.map((v:any) => `Error: ${v.title} `),
      content: payload?.map((v:any) => `${v?.detail} `),
      centered: true
    });
  } else {
    Modal.error({
      title: `Error: Произошла ошибка сервера.`,
      centered: true
    });
  }
};

export default handleReduxError;