import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILayerGroups } from '../../../models/ILayerGroups';
import { ILayerGroupsState } from './layerGroupsInterface';
import { toast } from 'react-toastify';
import handleReduxError from "src/shared/utils/handleReduxError";

import {
  getAllLayerGroups, 
  createLayerGroup,
  editLayerGroup, 
  deleteLayerGroup, 
  getSingleLayerGroup
} from './layerGroupsAction';

const initialState:ILayerGroupsState = {
  getLayerGroupsSuccess: false,
  getLayerGroupsLoading: false,
  getLayerGroupsError: false,
  layerGroups: {},

  createLayerGroupsSuccess: false,
  createLayerGroupsLoading: false,
  createLayerGroupsError: false,

  getSingleLayerGroupSuccess: false,
  getSingleLayerGroupLoading: false,
  getSingleLayerGroupError: false,
  singleLayerGroup: {},

  editLayerGroupSuccess: false,
  editLayerGroupLoading: false,
  editLayerGroupError: false,

  deleteLayerGroupSuccess: false,
  deleteLayerGroupLoading: false,
  deleteLayerGroupError: false,
};

const layerGroupSlice = createSlice({
  name: 'layerGroups',
  initialState,
  reducers: {
    cleanLayerGroups: (state) => {
      state.createLayerGroupsSuccess= false;
      state.createLayerGroupsLoading= false;
      state.createLayerGroupsError= false;

      state.editLayerGroupSuccess= false;
      state.editLayerGroupLoading= false;
      state.editLayerGroupError= false;

      state.deleteLayerGroupSuccess= false;
      state.deleteLayerGroupLoading= false;
      state.deleteLayerGroupError= false;
    },
  },
  extraReducers: {
    [getAllLayerGroups.fulfilled.type]: (state, action: PayloadAction<ILayerGroups>) => {
      state.getLayerGroupsLoading = false;
      state.getLayerGroupsError = false;
      state.layerGroups = action.payload;
      state.getLayerGroupsSuccess = true;
    },
    [getAllLayerGroups.pending.type]: (state) => {
      state.getLayerGroupsLoading = true;
    },
    [getAllLayerGroups.rejected.type]: (state, action: PayloadAction) => {
      state.getLayerGroupsLoading = false;
      state.getLayerGroupsError = true;
      handleReduxError(action.payload);
    },
    [createLayerGroup.fulfilled.type]: (state) => {
      state.createLayerGroupsLoading = false;
      state.createLayerGroupsError = false;
      state.createLayerGroupsSuccess = true;
      toast.success('Группа слоев успешно создана!');
    },
    [createLayerGroup.pending.type]: (state) => {
      state.createLayerGroupsLoading = true;
    },
    [createLayerGroup.rejected.type]: (state, action: PayloadAction) => {
      state.createLayerGroupsLoading = false;
      state.createLayerGroupsError = true;
      handleReduxError(action.payload);
    },
    [getSingleLayerGroup.fulfilled.type]: (state, action: PayloadAction<ILayerGroups>) => {
      state.getSingleLayerGroupLoading = false;
      state.getSingleLayerGroupError = false;
      state.singleLayerGroup = action.payload;
      state.getSingleLayerGroupSuccess = true;
    },
    [getSingleLayerGroup.pending.type]: (state) => {
      state.getSingleLayerGroupLoading = true;
    },
    [getSingleLayerGroup.rejected.type]: (state, action: PayloadAction) => {
      state.getSingleLayerGroupLoading = false;
      state.getSingleLayerGroupError = true;
      handleReduxError(action.payload);
    },
    [editLayerGroup.fulfilled.type]: (state) => {
      state.editLayerGroupLoading = false;
      state.editLayerGroupError = false;
      state.editLayerGroupSuccess = true;
      toast.success('Группа слоев успешно обновлена!');
    },
    [editLayerGroup.pending.type]: (state) => {
      state.editLayerGroupLoading = true;
    },
    [editLayerGroup.rejected.type]: (state, action: PayloadAction) => {
      state.editLayerGroupLoading = false;
      state.editLayerGroupError = true;
      handleReduxError(action.payload);
    },
    [deleteLayerGroup.fulfilled.type]: (state) => {
      state.deleteLayerGroupLoading = false;
      state.deleteLayerGroupError = false;
      state.deleteLayerGroupSuccess = true;
      toast.success('Группа слоев успешно удалена!');
    },
    [deleteLayerGroup.pending.type]: (state) => {
      state.deleteLayerGroupLoading = true;
    },
    [deleteLayerGroup.rejected.type]: (state, action: PayloadAction) => {
      state.deleteLayerGroupLoading = false;
      state.deleteLayerGroupError = true;
      handleReduxError(action.payload);
    },
  }
});

export const { cleanLayerGroups } = layerGroupSlice.actions;

export default layerGroupSlice.reducer;