import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPolygonTools } from 'src/models/IPolygonTools';
import { IPolygonToolsState } from './polygonToolsInterface';
import { toast } from 'react-toastify';
import handleReduxError from "src/shared/utils/handleReduxError";

import {
  getAllPolygonTools, 
  createPolygonTools, 
  getSinglePolygonTools, 
  editPolygonTools, 
  deletePolygonTools 
} from './polygonToolsAction';

const initialState:IPolygonToolsState = {
  getAllPolygonToolsSuccess: false,
  getAllPolygonToolsLoading: false,
  getAllPolygonToolsError: false,
  allPolygonTools: {},

  createPolygonToolsSuccess: false,
  createPolygonToolsLoading: false,
  createPolygonToolsError: false,
  createdPolygonTools: {},

  getSinglePolygonToolsSuccess: false,
  getSinglePolygonToolsLoading: false,
  getSinglePolygonToolsError: false,
  singlePolygonTools: {},

  editPolygonToolsSuccess: false,
  editPolygonToolsLoading: false,
  editPolygonToolsError: false,

  deletePolygonToolsSuccess: false,
  deletePolygonToolsLoading: false,
  deletePolygonToolsError: false
};

const polygonToolsSlice = createSlice({
  name: 'polygon-tools',
  initialState,
  reducers: {
    cleanPolygonTools: (state) => {
      state.createPolygonToolsSuccess=false;
      state.createPolygonToolsLoading=false;
      state.createPolygonToolsError=false;

      state.editPolygonToolsSuccess=false;
      state.editPolygonToolsLoading=false;
      state.editPolygonToolsError=false;

      state.deletePolygonToolsSuccess=false;
      state.deletePolygonToolsLoading=false;
      state.deletePolygonToolsError=false;
    },
  },
  extraReducers: {
    [getAllPolygonTools.fulfilled.type]: (state, action: PayloadAction<IPolygonTools>) => {
      state.getAllPolygonToolsLoading=false;
      state.getAllPolygonToolsError=false;
      state.allPolygonTools = action.payload;
      state.getAllPolygonToolsSuccess =true;
    },
    [getAllPolygonTools.pending.type]: (state) => {
      state.getAllPolygonToolsLoading = true;
    },
    [getAllPolygonTools.rejected.type]: (state, action: PayloadAction) => {
      state.getAllPolygonToolsLoading = false;
      state.getAllPolygonToolsError = true;
      handleReduxError(action.payload);
    },
    [createPolygonTools.fulfilled.type]: (state, action: PayloadAction<IPolygonTools>) => {
      state.createPolygonToolsLoading = false;
      state.createPolygonToolsError = false;
      state.createPolygonToolsSuccess = true;
      state.createdPolygonTools = action.payload;
      state.deletePolygonToolsSuccess = false;
      toast.success('Полигональные инструменты созданы успешно!');
    },
    [createPolygonTools.pending.type]: (state) => {
      state.createPolygonToolsLoading = true;
    },
    [createPolygonTools.rejected.type]: (state, action: PayloadAction) => {
      state.createPolygonToolsLoading = false;
      state.createPolygonToolsError = true;
      handleReduxError(action.payload);
    },
    [getSinglePolygonTools.fulfilled.type]: (state, action: PayloadAction<IPolygonTools>) => {
      state.getSinglePolygonToolsLoading = false;
      state.getSinglePolygonToolsError = false;
      state.singlePolygonTools = action.payload;
      state.getSinglePolygonToolsSuccess = true;
    },
    [getSinglePolygonTools.pending.type]: (state) => {
      state.getSinglePolygonToolsLoading = true;
    },
    [getSinglePolygonTools.rejected.type]: (state, action: PayloadAction) => {
      state.getSinglePolygonToolsLoading = false;
      state.getSinglePolygonToolsError = true;
      handleReduxError(action.payload);
    },
    [editPolygonTools.fulfilled.type]: (state) => {
      state.editPolygonToolsLoading = false;
      state.editPolygonToolsError = false;
      state.editPolygonToolsSuccess = true;
      toast.success('Полигон-инструменты успешно отредактированы!');
    },
    [editPolygonTools.pending.type]: (state) => {
      state.editPolygonToolsLoading = true;
    },
    [editPolygonTools.rejected.type]: (state, action: PayloadAction) => {
      state.editPolygonToolsLoading = false;
      state.editPolygonToolsError = true;
      handleReduxError(action.payload);
    },
    [deletePolygonTools.fulfilled.type]: (state) => {
      state.deletePolygonToolsLoading = false;
      state.deletePolygonToolsError = false;
      state.deletePolygonToolsSuccess = true;
    },
    [deletePolygonTools.pending.type]: (state) => {
      state.deletePolygonToolsLoading = true;
    },
    [deletePolygonTools.rejected.type]: (state, action: PayloadAction) => {
      state.deletePolygonToolsLoading = false;
      state.deletePolygonToolsError = true;
      handleReduxError(action.payload);
    },
  }
});

export const { cleanPolygonTools } = polygonToolsSlice.actions;

export default polygonToolsSlice.reducer;