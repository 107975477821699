import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "src/configs";
import {
  EditLayerPermission,
  LayerPermissionList,
} from "./layerPermissionInterface";

export const getLayerPermissions = createAsyncThunk(
  "getLayerPermissions",
  async (queryString: any, thunkAPI) => {
    try {
      const response = await axios.get<LayerPermissionList[]>(
        `${BASE_URL}/layer-permissions`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            accept: "application/json",
          },
          params: {
            ...queryString,
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const createLayerPermissions = createAsyncThunk(
  "createLayerPermissions",
  async (data: unknown, thunkAPI) => {
    try {
      const response = await axios.post<unknown>(
        `${BASE_URL}/layer-permissions`,
        data,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const getLayerPermissionsById = createAsyncThunk(
  "getLayerPermissionsById",
  async (data: EditLayerPermission, thunkAPI) => {
    try {
      const response = await axios.get<LayerPermissionList>(
        `${BASE_URL}/layer-permissions/${data.id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(
        err?.response?.data?.errors
      );
    }
  }
);

export const editLayerPermissions = createAsyncThunk(
  "editLayerPermissions",
  async (data: any, thunkAPI) => {
    delete data.id;
    delete data.layer_title;
    delete data.user_email;
    delete data.workspace_title;
    try {
      const response = await axios.put<any>(
        `${BASE_URL}/layer-permissions`,
        data,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const deleteLayerPermissions = createAsyncThunk(
  "deleteLayerPermissions",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/layer-permissions/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);
