import axios from "axios";
import { BASE_URL } from "src/configs/index";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IAttribute,
  IPlacemarks,
  IEditPlacemarks,
  ICreatePlacemarks,
} from "src/models/IPlacemarks";

export const getAllPlacemarks = createAsyncThunk(
  "get-all-placemarks",
  async (params: Record<string, string> | undefined, thunkAPI) => {
    try {
      const response = await axios.get<IPlacemarks>(
        `${BASE_URL}/placemarks?${params?.columns}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            accept: "application/json",
          },
          params,
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const getSinglePlacemark = createAsyncThunk(
  "get-single-placemark",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.get<IPlacemarks>(
        `${BASE_URL}/placemarks/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const deletePlacemark = createAsyncThunk(
  "delete-placemark",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.delete(`${BASE_URL}/placemarks/${id}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
          accept: "application/json",
        },
      });
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const editPlacemark = createAsyncThunk(
  "edit-placemark",
  async (formData: IEditPlacemarks, thunkAPI) => {
    try {
      const response = await axios.put<IEditPlacemarks>(
        `${BASE_URL}/placemarks/${formData.id}`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const createPlacemarks = createAsyncThunk(
  "create-placemarks",
  async (formData: ICreatePlacemarks, thunkAPI) => {
    try {
      const response = await axios.post<IAttribute>(
        `${BASE_URL}/placemarks`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);
