import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPolygonColumns } from 'src/models/IPolygonColumns';
import { IPolygonColumnsState } from './polygonColumnsInterface';
import { toast } from 'react-toastify';
import handleReduxError from "src/shared/utils/handleReduxError";

import {
  getPolygonColumns, 
  createPolygonColumns, 
  getSinglePolygonColumn, 
  editPolygonColumns, 
  deletePolygonColumns, 
  getPolygonColumnsType
} from './polygonColumnsAction';

const initialState:IPolygonColumnsState = {
  getPolygonColumnsSuccess: false,
  getPolygonColumnsLoading: false,
  getPolygonColumnsError: false,
  polygonColumns: {},

  getPolygonColumnsTypeSuccess: false,
  getPolygonColumnsTypeError: false,
  getPolygonColumnsTypeLoading: false,
  polygonColumnsType: {},

  createPolygonColumnsSuccess: false,
  createPolygonColumnsLoading: false,
  createPolygonColumnsError: false,

  getSinglePolygonColumnSuccess: false,
  getSinglePolygonColumnLoading: false,
  getSinglePolygonColumnError: false,
  singlePolygonColumn: {},

  editPolygonColumnsSuccess: false,
  editPolygonColumnsLoading: false,
  editPolygonColumnsError: false,

  deletePolygonColumnsSuccess: false,
  deletePolygonColumnsLoading: false,
  deletePolygonColumnsError: false,
};

const polygonColumnsSlice = createSlice({
  name: 'polygonColumns',
  initialState,
  reducers: {
    cleanPolygonColumns: (state) => {
      state.createPolygonColumnsSuccess= false;
      state.createPolygonColumnsLoading= false;
      state.createPolygonColumnsError= false;

      state.editPolygonColumnsSuccess= false;
      state.editPolygonColumnsLoading= false;
      state.editPolygonColumnsError= false;

      state.deletePolygonColumnsSuccess= false;
      state.deletePolygonColumnsLoading= false;
      state.deletePolygonColumnsError= false;
    },
  },
  extraReducers: {
    [getPolygonColumns.fulfilled.type]: (state, action: PayloadAction<IPolygonColumns>) => {
      state.getPolygonColumnsLoading = false;
      state.getPolygonColumnsError = false;
      state.polygonColumns = action.payload;
      state.getPolygonColumnsSuccess = true;
    },
    [getPolygonColumns.pending.type]: (state) => {
      state.getPolygonColumnsLoading = true;
    },
    [getPolygonColumns.rejected.type]: (state, action: PayloadAction) => {
      state.getPolygonColumnsLoading = false;
      state.getPolygonColumnsError = true;
      handleReduxError(action.payload);
    },
    [getPolygonColumnsType.fulfilled.type]: (state, action: PayloadAction<IPolygonColumns>) => {
      state.getPolygonColumnsTypeLoading = false;
      state.getPolygonColumnsTypeError = false;
      state.polygonColumnsType = action.payload;
      state.getPolygonColumnsTypeSuccess = true;
    },
    [getPolygonColumnsType.pending.type]: (state) => {
      state.getPolygonColumnsTypeLoading = true;
    },
    [getPolygonColumnsType.rejected.type]: (state, action: PayloadAction) => {
      state.getPolygonColumnsTypeLoading = false;
      state.getPolygonColumnsTypeError = true;
      handleReduxError(action.payload);
    },
    [createPolygonColumns.fulfilled.type]: (state) => {
      state.createPolygonColumnsLoading = false;
      state.createPolygonColumnsError = false;
      state.createPolygonColumnsSuccess = true;
      toast.success('Тип данных создан успешно!');
    },
    [createPolygonColumns.pending.type]: (state) => {
      state.createPolygonColumnsLoading = true;
    },
    [createPolygonColumns.rejected.type]: (state, action: PayloadAction) => {
      state.createPolygonColumnsLoading = false;
      state.createPolygonColumnsError = true;
      handleReduxError(action.payload);
    },
    [getSinglePolygonColumn.fulfilled.type]: (state, action: PayloadAction<IPolygonColumns>) => {
      state.getSinglePolygonColumnLoading = false;
      state.getSinglePolygonColumnError = false;
      state.singlePolygonColumn = action.payload;
      state.getSinglePolygonColumnSuccess = true;
    },
    [getSinglePolygonColumn.pending.type]: (state) => {
      state.getSinglePolygonColumnLoading = true;
    },
    [getSinglePolygonColumn.rejected.type]: (state, action: PayloadAction) => {
      state.getSinglePolygonColumnLoading = false;
      state.getSinglePolygonColumnError = true;
      handleReduxError(action.payload);
    },
    [editPolygonColumns.fulfilled.type]: (state) => {
      state.editPolygonColumnsLoading = false;
      state.editPolygonColumnsError = false;
      state.editPolygonColumnsSuccess = true;
      toast.success('Kолонок успешно отредактирован!');
    },
    [editPolygonColumns.pending.type]: (state) => {
      state.editPolygonColumnsLoading = true;
    },
    [editPolygonColumns.rejected.type]: (state, action: PayloadAction) => {
      state.editPolygonColumnsLoading = false;
      state.editPolygonColumnsError = true;
      handleReduxError(action.payload);
    },
    [deletePolygonColumns.fulfilled.type]: (state) => {
      state.deletePolygonColumnsLoading = false;
      state.deletePolygonColumnsError = false;
      state.deletePolygonColumnsSuccess = true;
      toast.success('Kолонок успешно удален!');
    },
    [deletePolygonColumns.pending.type]: (state) => {
      state.deletePolygonColumnsLoading = true;
    },
    [deletePolygonColumns.rejected.type]: (state, action: PayloadAction) => {
      state.deletePolygonColumnsLoading = false;
      state.deletePolygonColumnsError = true;
      handleReduxError(action.payload);
    },
  }
});

export const { cleanPolygonColumns } = polygonColumnsSlice.actions;

export default polygonColumnsSlice.reducer;