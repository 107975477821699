import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRoles } from 'src/models/IRoles';
import { IRolesState } from './rolesInterface';
import { toast } from 'react-toastify';
import handleReduxError from "src/shared/utils/handleReduxError";
import {
  getAllRoles, 
  createRoles, 
  getSingleRole,
  editRoles, 
  deleteRoles
} from './rolesAction';

const initialState:IRolesState = {
  getAllRolesSuccess: false,
  getAllRolesLoading: false,
  getAllRolesError: false,
  allRoles: {},

  createRolesSuccess: false,
  createRolesLoading: false,
  createRolesError: false,
  createdRoles: {},

  getSingleRolesSuccess: false,
  getSingleRolesLoading: false,
  getSingleRolesError: false,
  singleRole: {},

  editRolesSuccess: false,
  editRolesLoading: false,
  editRolesError: false,

  deleteRolesSuccess: false,
  deleteRolesLoading: false,
  deleteRolesError: false
};

const RolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    cleanRoles: (state) => {
      state.createRolesSuccess=false;
      state.createRolesLoading=false;
      state.createRolesError=false;

      state.editRolesSuccess=false;
      state.editRolesLoading=false;
      state.editRolesError=false;

      state.deleteRolesSuccess=false;
      state.deleteRolesLoading=false;
      state.deleteRolesError=false;

      state.getSingleRolesLoading=false;
      state.getSingleRolesError=false;
      state.getSingleRolesSuccess=false;
      state.singleRole={};
    },
  },
  extraReducers: {
    [getAllRoles.fulfilled.type]: (state, action: PayloadAction<IRoles>) => {
      state.getAllRolesLoading=false;
      state.getAllRolesError=false;
      state.allRoles = action.payload;
      state.getAllRolesSuccess =true;
    },
    [getAllRoles.pending.type]: (state) => {
      state.getAllRolesLoading = true;
    },
    [getAllRoles.rejected.type]: (state, action: PayloadAction) => {
      state.getAllRolesLoading = false;
      state.getAllRolesError = true;
      handleReduxError(action.payload);
    },
    [createRoles.fulfilled.type]: (state, action: PayloadAction<IRoles>) => {
      state.createRolesLoading = false;
      state.createRolesError = false;
      state.createRolesSuccess = true;
      state.createdRoles = action.payload;
      toast.success('Роль создана успешно!');
    },
    [createRoles.pending.type]: (state) => {
      state.createRolesLoading = true;
    },
    [createRoles.rejected.type]: (state, action: PayloadAction) => {
      state.createRolesLoading = false;
      state.createRolesError = true;
      handleReduxError(action.payload);
    },
    [getSingleRole.fulfilled.type]: (state, action: PayloadAction<IRoles>) => {
      state.getSingleRolesLoading = false;
      state.getSingleRolesError = false;
      state.singleRole = action.payload;
      state.getSingleRolesSuccess = true;
    },
    [getSingleRole.pending.type]: (state) => {
      state.getSingleRolesLoading = true;
    },
    [getSingleRole.rejected.type]: (state, action: PayloadAction) => {
      state.getSingleRolesLoading = false;
      state.getSingleRolesError = true;
      handleReduxError(action.payload);
    },
    [editRoles.fulfilled.type]: (state) => {
      state.editRolesLoading = false;
      state.editRolesError = false;
      state.editRolesSuccess = true;
      toast.success('Роли успешно отредактированы!');
    },
    [editRoles.pending.type]: (state) => {
      state.editRolesLoading = true;
    },
    [editRoles.rejected.type]: (state, action: PayloadAction) => {
      state.editRolesLoading = false;
      state.editRolesError = true;
      handleReduxError(action.payload);
    },
    [deleteRoles.fulfilled.type]: (state) => {
      state.deleteRolesLoading = false;
      state.deleteRolesError = false;
      state.deleteRolesSuccess = true;
    },
    [deleteRoles.pending.type]: (state) => {
      state.deleteRolesLoading = true;
    },
    [deleteRoles.rejected.type]: (state, action: PayloadAction) => {
      state.deleteRolesLoading = false;
      state.deleteRolesError = true;
      handleReduxError(action.payload);
    },
  }
});

export const { cleanRoles } = RolesSlice.actions;

export default RolesSlice.reducer;