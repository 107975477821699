import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPolygons } from '../../../models/IPolygons';
import { IPolygonsState } from './polygonsInterface';
import { toast } from 'react-toastify';
import handleReduxError from "src/shared/utils/handleReduxError";

import {
  getAllPolygons, 
  createPolygons, 
  getSinglePolygon, 
  editPolygons, 
  deletePolygons 
} from './polygonsAction';

const initialState:IPolygonsState = {
  getAllPolygonsSuccess: false,
  getAllPolygonsLoading: false,
  getAllPolygonsError: false,
  allPolygons: {},

  createPolygonsSuccess: false,
  createPolygonsLoading: false,
  createPolygonsError: false,

  getSinglePolygonSuccess: false,
  getSinglePolygonLoading: false,
  getSinglePolygonError: false,
  singlePolygon: {},

  editPolygonsSuccess: false,
  editPolygonsLoading: false,
  editPolygonsError: false,

  deletePolygonsSuccess: false,
  deletePolygonsLoading: false,
  deletePolygonsError: false,
};

const polygonsSlice = createSlice({
  name: 'polygons',
  initialState,
  reducers: {
    cleanPolygons: (state) => {
      state.createPolygonsSuccess= false;
      state.createPolygonsLoading= false;
      state.createPolygonsError= false;

      state.editPolygonsSuccess= false;
      state.editPolygonsLoading= false;
      state.editPolygonsError= false;

      state.deletePolygonsSuccess= false;
      state.deletePolygonsLoading= false;
      state.deletePolygonsError= false;

      state.getSinglePolygonLoading= false;
      state.getSinglePolygonError= false;
      state.getSinglePolygonSuccess= false;
    },
  },
  extraReducers: {
    [getAllPolygons.fulfilled.type]: (state, action: PayloadAction<IPolygons>) => {
      state.getAllPolygonsLoading = false;
      state.getAllPolygonsError = false;
      state.allPolygons = action.payload;
      state.getAllPolygonsSuccess = true;
    },
    [getAllPolygons.pending.type]: (state) => {
      state.getAllPolygonsLoading = true;
    },
    [getAllPolygons.rejected.type]: (state, action: PayloadAction) => {
      state.getAllPolygonsLoading = false;
      state.getAllPolygonsError = true;
      handleReduxError(action.payload);
    },
    [createPolygons.fulfilled.type]: (state) => {
      state.createPolygonsLoading = false;
      state.createPolygonsError = false;
      state.createPolygonsSuccess = true;
      toast.success('Полигоны созданы успешно!');
    },
    [createPolygons.pending.type]: (state) => {
      state.createPolygonsLoading = true;
    },
    [createPolygons.rejected.type]: (state, action: PayloadAction) => {
      state.createPolygonsLoading = false;
      state.createPolygonsError = true;
      handleReduxError(action.payload);
    },
    [getSinglePolygon.fulfilled.type]: (state, action: PayloadAction<IPolygons>) => {
      state.getSinglePolygonLoading = false;
      state.getSinglePolygonError = false;
      state.singlePolygon = action.payload;
      state.getSinglePolygonSuccess = true;
    },
    [getSinglePolygon.pending.type]: (state) => {
      state.getSinglePolygonLoading = true;
    },
    [getSinglePolygon.rejected.type]: (state, action: PayloadAction) => {
      state.getSinglePolygonLoading = false;
      state.getSinglePolygonError = true;
      handleReduxError(action.payload);
    },
    [editPolygons.fulfilled.type]: (state) => {
      state.editPolygonsLoading = false;
      state.editPolygonsError = false;
      state.editPolygonsSuccess = true;
      toast.success('Полигоны успешно отредактированы!');
    },
    [editPolygons.pending.type]: (state) => {
      state.editPolygonsLoading = true;
    },
    [editPolygons.rejected.type]: (state, action: PayloadAction) => {
      state.editPolygonsLoading = false;
      state.editPolygonsError = true;
      handleReduxError(action.payload);
    },
    [deletePolygons.fulfilled.type]: (state) => {
      state.deletePolygonsLoading = false;
      state.deletePolygonsError = false;
      state.deletePolygonsSuccess = true;
    },
    [deletePolygons.pending.type]: (state) => {
      state.deletePolygonsLoading = true;
    },
    [deletePolygons.rejected.type]: (state, action: PayloadAction) => {
      state.deletePolygonsLoading = false;
      state.deletePolygonsError = true;
      handleReduxError(action.payload);
    },
  }
});

export const { cleanPolygons } = polygonsSlice.actions;

export default polygonsSlice.reducer;