import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PlacemarksState } from "./placemarksInterface";
import { IPlacemarks } from "src/models/IPlacemarks";
import { toast } from "react-toastify";
import handleReduxError from "src/shared/utils/handleReduxError";

import {
  getAllPlacemarks,
  getSinglePlacemark,
  deletePlacemark,
  createPlacemarks,
  editPlacemark,
} from "./palcemarksAction";
import { PLACEMARK_FILTER_VALUE } from "src/shared/constants/placemarkFilter";

const initialState: PlacemarksState = {
  getAllPlacemarksLoading: false,
  getAllPlacemarksSuccess: false,
  getAllPlacemarksError: false,
  allPlacemarks: {},

  createPlacemarksLoading: false,
  createPlacemarksSuccess: false,
  createPlacemarksError: false,

  getSinglePlacemarkLoading: false,
  getSinglePlacemarkSuccess: false,
  getSinglePlacemarkError: false,
  singlePlacemark: {},

  editPlacemarkLoading: false,
  editPlacemarkSuccess: false,
  editPlacemarkError: false,

  deletePlacemarkLoading: false,
  deletePlacemarkSuccess: false,
  deletePlacemarkError: false,

  placemarkFilter: PLACEMARK_FILTER_VALUE,
};

const placemarksSlice = createSlice({
  name: "placemarks",
  initialState,
  reducers: {
    cleanPlacemarks: (state) => {
      state.createPlacemarksLoading = false;
      state.createPlacemarksSuccess = false;
      state.createPlacemarksError = false;

      state.editPlacemarkLoading = false;
      state.editPlacemarkSuccess = false;
      state.editPlacemarkError = false;

      state.deletePlacemarkLoading = false;
      state.deletePlacemarkSuccess = false;
      state.deletePlacemarkError = false;

      state.getSinglePlacemarkLoading = false;
      state.getSinglePlacemarkError = false;
      state.getSinglePlacemarkSuccess = false;
    },
    updatePlacemarkFilter(state, action: PayloadAction<any>) {
      state.placemarkFilter = action.payload;
    },
  },
  extraReducers: {
    [getAllPlacemarks.fulfilled.type]: (
      state,
      action: PayloadAction<IPlacemarks>
    ) => {
      state.getAllPlacemarksLoading = false;
      state.getAllPlacemarksError = false;
      state.allPlacemarks = action.payload;
      state.getAllPlacemarksSuccess = true;
    },
    [getAllPlacemarks.pending.type]: (state) => {
      state.getAllPlacemarksLoading = true;
    },
    [getAllPlacemarks.rejected.type]: (state, action: PayloadAction) => {
      state.getAllPlacemarksLoading = false;
      state.getAllPlacemarksError = true;
      handleReduxError(action.payload);
    },
    [getSinglePlacemark.fulfilled.type]: (
      state,
      action: PayloadAction<IPlacemarks>
    ) => {
      state.getSinglePlacemarkLoading = false;
      state.getSinglePlacemarkError = false;
      state.singlePlacemark = action.payload;
      state.getSinglePlacemarkSuccess = true;
    },
    [getSinglePlacemark.pending.type]: (state) => {
      state.getSinglePlacemarkLoading = true;
    },
    [getSinglePlacemark.rejected.type]: (state, action: PayloadAction) => {
      state.getSinglePlacemarkLoading = false;
      state.getSinglePlacemarkError = true;
      handleReduxError(action.payload);
    },
    [createPlacemarks.fulfilled.type]: (state) => {
      state.createPlacemarksLoading = false;
      state.createPlacemarksError = false;
      state.createPlacemarksSuccess = true;
      toast.success("Метка создана успешно!");
    },
    [createPlacemarks.pending.type]: (state) => {
      state.createPlacemarksLoading = true;
    },
    [createPlacemarks.rejected.type]: (state, action: PayloadAction) => {
      state.createPlacemarksLoading = false;
      state.createPlacemarksError = true;
      handleReduxError(action.payload);
    },
    [editPlacemark.fulfilled.type]: (state) => {
      state.editPlacemarkLoading = false;
      state.editPlacemarkError = false;
      state.editPlacemarkSuccess = true;
      toast.success("Метка успешно отредактирована!");
    },
    [editPlacemark.pending.type]: (state) => {
      state.editPlacemarkLoading = true;
    },
    [editPlacemark.rejected.type]: (state, action: PayloadAction) => {
      state.editPlacemarkLoading = false;
      state.editPlacemarkError = true;
      handleReduxError(action.payload);
    },
    [deletePlacemark.fulfilled.type]: (state) => {
      state.deletePlacemarkLoading = false;
      state.deletePlacemarkError = false;
      state.deletePlacemarkSuccess = true;
    },
    [deletePlacemark.pending.type]: (state) => {
      state.deletePlacemarkLoading = true;
    },
    [deletePlacemark.rejected.type]: (state, action: PayloadAction) => {
      state.deletePlacemarkLoading = false;
      state.deletePlacemarkError = true;
      handleReduxError(action.payload);
    },
  },
});

export const { cleanPlacemarks, updatePlacemarkFilter } =
  placemarksSlice.actions;

export default placemarksSlice.reducer;
