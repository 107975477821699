import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser, ICreatedUsers } from 'src/models/IUser';
import { UserState } from './userInterface';
import { toast } from 'react-toastify';
import handleReduxError from "src/shared/utils/handleReduxError";

import {
  getCurrentUser, 
  loginUser, 
  logoutUser, 
  updateUser,
  createUser,
  getAllUsers,
  getSingleUser,
  editUser,
  deleteUser
} from './userAction';

const initialState: UserState = {
  user: {},
  getUserLoading: false,
  getUserSuccess: false,
  getUserError: false,

  loginUserLoading: false,
  loginUserSuccess: false,
  loginUserError: false,

  logoutUserLoading: false,
  logoutUserSuccess: false,
  logoutUserError: false,

  updateUserLoading: false,
  updateUserSuccess: false,
  updateUserError: false,

  createUserLoading: false,
  createUserSuccess: false,
  createUserError: false,

  getAllUsersLoading: false,
  getAllUsersSuccess: false,
  getAllUsersError: false,
  allUsers: {},

  getSingleUserLoading: false,
  getSingleUserSuccess: false,
  getSingleUserError: false,
  singleUser: {},

  editUserLoading: false,
  editUserSuccess: false,
  editUserError: false,

  deleteUserLoading: false,
  deleteUserSuccess: false,
  deleteUserError: false
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: { 
    cleanUsers: (state) => {
      state.createUserLoading= false;
      state.createUserSuccess= false;
      state.createUserError= false;

      state.loginUserLoading = false;
      state.loginUserError = false;
      state.loginUserSuccess = false;

      state.editUserLoading= false;
      state.editUserSuccess= false;
      state.editUserError= false;

      state.deleteUserLoading= false;
      state.deleteUserSuccess= false;
      state.deleteUserError= false;

      state.getSingleUserLoading= false;
      state.getSingleUserSuccess= false;
      state.getSingleUserError= false;
      state.singleUser = {};
    },
  },
  extraReducers: {
    [getCurrentUser.fulfilled.type]: (state, action: PayloadAction<IUser>) => {
      state.getUserLoading = false;
      state.getUserError = false;
      state.user = action.payload;
      state.getUserSuccess = true;
    },
    [getCurrentUser.pending.type]: (state) => {
      state.getUserLoading = true;
    },
    [getCurrentUser.rejected.type]: (state, action: PayloadAction) => {
      state.getUserLoading = false;
      state.getUserError = true;
      handleReduxError(action.payload);
    },
    [loginUser.fulfilled.type]: (state) => {
      state.loginUserLoading = false;
      state.loginUserError = false;
      state.loginUserSuccess = true;
      toast.success('Успешно!')
    },
    [loginUser.pending.type]: (state) => {
      state.loginUserLoading = true;
    },
    [loginUser.rejected.type]: (state, action: PayloadAction) => {
      state.loginUserLoading = false;
      state.loginUserError = true;
      handleReduxError(action.payload);
    },
    [logoutUser.fulfilled.type]: (state) => {
      state.logoutUserLoading = false;
      state.logoutUserError = false;
      state.logoutUserSuccess = true;
      toast.success('Успешно!');
    },
    [logoutUser.pending.type]: (state) => {
      state.logoutUserLoading = true;
    },
    [logoutUser.rejected.type]: (state, action: PayloadAction) => {
      state.logoutUserLoading = false;
      state.logoutUserError = true;
      handleReduxError(action.payload);
    },
    [updateUser.fulfilled.type]: (state, action: PayloadAction<IUser>) => {
      state.updateUserLoading = false;
      state.user = action.payload;
      state.updateUserSuccess = true;
      state.updateUserError = false;
      toast.success('Пользователь успешно обновлен!');
    },
    [updateUser.pending.type]: (state) => {
      state.updateUserLoading = true;
    },
    [updateUser.rejected.type]: (state, action: PayloadAction) => {
      state.updateUserLoading = false;
      state.updateUserError = true;
      handleReduxError(action.payload);
    },
    [createUser.fulfilled.type]: (state) => {
      state.createUserSuccess = true;
      state.createUserLoading = false;
      state.createUserError = false;
      toast.success('Пользователь создан успешно!');
    },
    [createUser.pending.type]: (state) => {
      state.createUserLoading = true;
    },
    [createUser.rejected.type]: (state, action: PayloadAction) => {
      state.createUserLoading = false;
      state.createUserError = true;
      handleReduxError(action.payload);
    },
     [getAllUsers.fulfilled.type]: (state, action: PayloadAction<ICreatedUsers>) => {
      state.getAllUsersSuccess = true;
      state.getAllUsersLoading = false;
      state.allUsers = action.payload;
      state.getAllUsersError = false;
    },
    [getAllUsers.pending.type]: (state) => {
      state.getAllUsersLoading = true;
    },
    [getAllUsers.rejected.type]: (state, action: PayloadAction) => {
      state.getAllUsersLoading = false;
      state.getAllUsersError = true;
      handleReduxError(action.payload);
    },
    [getSingleUser.fulfilled.type]: (state, action: PayloadAction<ICreatedUsers>) => {
    state.getSingleUserSuccess = true;
    state.getSingleUserLoading = false;
    state.singleUser = action.payload;
    state.getSingleUserError = false;
    },
    [getSingleUser.pending.type]: (state) => {
      state.getSingleUserLoading = true;
    },
    [getSingleUser.rejected.type]: (state, action: PayloadAction) => {
      state.getSingleUserLoading = false;
      state.getSingleUserError = true;
      handleReduxError(action.payload);
    },
    [editUser.fulfilled.type]: (state) => {
      state.editUserSuccess = true;
      state.editUserLoading = false;
      state.editUserError = false;
      toast.success('Пользователь успешно отредактировал!');
    },
    [editUser.pending.type]: (state) => {
      state.editUserLoading = true;
    },
    [editUser.rejected.type]: (state, action: PayloadAction) => {
      state.editUserLoading = false;
      state.editUserError = true;
      handleReduxError(action.payload);
    },
     [deleteUser.fulfilled.type]: (state) => {
      state.deleteUserSuccess = true;
      state.deleteUserLoading = false;
      state.deleteUserError = false;
      toast.success('Пользователь успешно удален!');
    },
    [deleteUser.pending.type]: (state) => {
      state.deleteUserLoading = true;
    },
    [deleteUser.rejected.type]: (state, action: PayloadAction) => {
      state.deleteUserLoading = false;
      state.deleteUserError = true;
      handleReduxError(action.payload);
    },
  }
});

export const { cleanUsers } = userSlice.actions;

export default userSlice.reducer;