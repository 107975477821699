import axios from "axios";
import {
  IUser,
  ILoginUser,
  IEditUser,
  CreateUser,
  ICreatedUsers,
  IEditCreatedUser,
} from "src/models/IUser";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "src/configs";

export const loginUser = createAsyncThunk(
  "loginUser",
  async (data: ILoginUser, thunkAPI) => {
    const newData = Buffer.from(`${data.username}:${data.password}`, 'utf8').toString('base64');
    try {
      const response = await axios({
        url: `${BASE_URL}/users/login`, 
        method: "POST",
        withCredentials: true,
        headers: {
          'Authorization': `Basic ${newData}`,
        },
      });
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const getCurrentUser = createAsyncThunk(
  "getCurrentUser",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<IUser>(`${BASE_URL}/users/me`, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      });
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const updateUser = createAsyncThunk(
  "update-user",
  async (formData: IEditUser, thunkAPI) => {
    try {
      const response = await axios.put<IUser>(
        `${BASE_URL}/users/me`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const logoutUser = createAsyncThunk(
  "logoutUser",
  async (_, thunkAPI) => {
    try {
      const response = await axios.post(`${BASE_URL}/users/logout`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const createUser = createAsyncThunk(
  "create-user",
  async (data: CreateUser, thunkAPI) => {
    try {
      const response = await axios.post(`${BASE_URL}/users`, data, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const getAllUsers = createAsyncThunk(
  "get-all-users",
  async (queryParams: any , thunkAPI: any) => {
    try {
      const response = await axios.get<ICreatedUsers>(`${BASE_URL}/users`, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
        params: {
          ...queryParams
        }
      });
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const getSingleUser = createAsyncThunk(
  "get-single-user",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.get<ICreatedUsers>(
        `${BASE_URL}/users/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const editUser = createAsyncThunk(
  "edit-user",
  async (data: IEditCreatedUser, thunkAPI) => {
    try {
      const response = await axios.put<IEditCreatedUser>(
        `${BASE_URL}/users/${data.id}`,
        data,
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "delete-user",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.delete(`${BASE_URL}/users/${id}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      });
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);
