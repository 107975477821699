import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICascadeTools } from 'src/models/ICascadeTools';
import { ICascadeToolsState } from './cascadeToolsInterface';
import { toast } from 'react-toastify';
import { message } from 'antd';
import handleReduxError from "src/shared/utils/handleReduxError";

import {
  getAllCascadeTools,
  createCascadeTools,
  getSingleCascadeTools,
  editCascadeTools,
  deleteCascadeTools
} from './cascadeToolsAction';

const initialState:ICascadeToolsState = {
  getAllCascadeToolsSuccess: false,
  getAllCascadeToolsLoading: false,
  getAllCascadeToolsError: false,
  allCascadeTools: {},

  createCascadeToolsSuccess: false,
  createCascadeToolsLoading: false,
  createCascadeToolsError: false,
  createdCascadeTools: {},

  getSingleCascadeToolsSuccess: false,
  getSingleCascadeToolsLoading: false,
  getSingleCascadeToolsError: false,
  singleCascadeTools: {},

  editCascadeToolsSuccess: false,
  editCascadeToolsLoading: false,
  editCascadeToolsError: false,

  deleteCascadeToolsSuccess: false,
  deleteCascadeToolsLoading: false,
  deleteCascadeToolsError: false
};

const cascadeToolsSlice = createSlice({
  name: 'cascade-tools',
  initialState,
  reducers: {
    cleanCascadeTools: (state) => {
      state.createCascadeToolsSuccess=false;
      state.createCascadeToolsLoading=false;
      state.createCascadeToolsError=false;
      state.createdCascadeTools={};

      state.editCascadeToolsSuccess=false;
      state.editCascadeToolsLoading=false;
      state.editCascadeToolsError=false;

      state.deleteCascadeToolsSuccess=false;
      state.deleteCascadeToolsLoading=false;
      state.deleteCascadeToolsError=false;

      state.getSingleCascadeToolsLoading=false;
      state.getSingleCascadeToolsError=false;
      state.getSingleCascadeToolsSuccess=false;
      state.singleCascadeTools={};
    },
  },
  extraReducers: {
    [getAllCascadeTools.fulfilled.type]: (state, action: PayloadAction<ICascadeTools>) => {
      state.getAllCascadeToolsLoading = false;
      state.getAllCascadeToolsError = false;
      state.allCascadeTools = action.payload;
      state.getAllCascadeToolsSuccess = true;
    },
    [getAllCascadeTools.pending.type]: (state) => {
      state.getAllCascadeToolsLoading = true;
    },
    [getAllCascadeTools.rejected.type]: (state, action: PayloadAction) => {
      state.getAllCascadeToolsLoading = false;
      state.getAllCascadeToolsError = true;
      handleReduxError(action.payload);
    },
    [createCascadeTools.fulfilled.type]: (state, action: PayloadAction<ICascadeTools>) => {
      state.createCascadeToolsLoading = false;
      state.createCascadeToolsError = false;
      state.createCascadeToolsSuccess = true;
      state.createdCascadeTools = action.payload;
      state.deleteCascadeToolsSuccess = false;
      toast.success('Каскадные инструменты созданные успешно!');
    },
    [createCascadeTools.pending.type]: (state) => {
      state.createCascadeToolsLoading = true;
    },
    [createCascadeTools.rejected.type]: (state, action: PayloadAction) => {
      state.createCascadeToolsLoading = false;
      state.createCascadeToolsError = true;
      handleReduxError(action.payload);
    },
    [getSingleCascadeTools.fulfilled.type]: (state, action: PayloadAction<ICascadeTools>) => {
      state.getSingleCascadeToolsLoading = false;
      state.getSingleCascadeToolsError = false;
      state.singleCascadeTools = action.payload;
      state.getSingleCascadeToolsSuccess = true;
    },
    [getSingleCascadeTools.pending.type]: (state) => {
      state.getSingleCascadeToolsLoading = true;
    },
    [getSingleCascadeTools.rejected.type]: (state, action: PayloadAction) => {
      state.getSingleCascadeToolsLoading = false;
      state.getSingleCascadeToolsError = true;
      handleReduxError(action.payload);
    },
    [editCascadeTools.fulfilled.type]: (state) => {
      state.editCascadeToolsLoading = false;
      state.editCascadeToolsError = false;
      state.editCascadeToolsSuccess = true;
      toast.success('Каскадные инструменты успешно изменены!');
    },
    [editCascadeTools.pending.type]: (state) => {
      state.editCascadeToolsLoading = true;
    },
    [editCascadeTools.rejected.type]: (state, action: PayloadAction) => {
      state.editCascadeToolsLoading = false;
      state.editCascadeToolsError = true;
      handleReduxError(action.payload);
    },
    [deleteCascadeTools.fulfilled.type]: (state) => {
      state.deleteCascadeToolsLoading = false;
      state.deleteCascadeToolsError = false;
      state.deleteCascadeToolsSuccess = true;
      message.success("Каскадные инструменты успешно удалены!")
    },
    [deleteCascadeTools.pending.type]: (state) => {
      state.deleteCascadeToolsLoading = true;
    },
    [deleteCascadeTools.rejected.type]: (state, action: PayloadAction) => {
      state.deleteCascadeToolsLoading = false;
      state.deleteCascadeToolsError = true;
      handleReduxError(action.payload);
    }
  }
});

export const { cleanCascadeTools } = cascadeToolsSlice.actions;

export default cascadeToolsSlice.reducer;