import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILayers } from "src/models/ILayers";
import { ILayersState, LayerType } from "./layersInterface";
import { toast } from "react-toastify";
import handleReduxError from "src/shared/utils/handleReduxError";

import {
  getAllLayers,
  createLayer,
  editLayer,
  deleteLayer,
  getSingleLayer,
  createLayerFromFile,
  getFilteredLayers,
  getLayersTypes,
  getNonFilteredLayers,
} from "./layersAction";

const initialState: ILayersState = {
  getLayersSuccess: false,
  getLayersLoading: false,
  getLayersError: false,
  layers: {},

  getNonFilteredLayersSuccess: false,
  getNonFilteredLayersError: false,
  getNonFilteredLayersLoading: false,
  nonFilteredLayers: {},

  getLayersTypesSuccess: false,
  getLayersTypesError: false,
  getLayersTypesLoading: false,
  layersTypes: {},

  createLayerSuccess: false,
  createLayerLoading: false,
  createLayerError: false,

  getSingleLayerSuccess: false,
  getSingleLayerLoading: false,
  getSingleLayerError: false,
  singleLayer: {},

  editLayerSuccess: false,
  editLayerLoading: false,
  editLayerError: false,

  deleteLayerSuccess: false,
  deleteLayerLoading: false,
  deleteLayerError: false,

  createLayerFromFileSuccess: false,
  createLayerFromFileLoading: false,
  createLayerFromFileError: false,

  getFilteredLayersSuccess: false,
  getFilteredLayersError: false,
  getFilteredLayersLoading: false,
  filteredLayers: {},

  selectedLayer: null,
  activeLayers: [],
};

const layersSlice = createSlice({
  name: "layers",
  initialState,
  reducers: {
    cleanLayers: (state) => {
      state.createLayerSuccess = false;
      state.createLayerLoading = false;
      state.createLayerError = false;

      state.editLayerSuccess = false;
      state.editLayerLoading = false;
      state.editLayerError = false;

      state.deleteLayerSuccess = false;
      state.deleteLayerLoading = false;
      state.deleteLayerError = false;

      state.createLayerFromFileSuccess = false;
      state.createLayerFromFileLoading = false;
      state.createLayerFromFileError = false;

      state.getSingleLayerSuccess = false;
      state.getSingleLayerLoading = false;
      state.getSingleLayerError = false;
    },
    updateSelectedLayer(state, action: PayloadAction<LayerType | null>) {
      state.selectedLayer = action.payload;
    },
    updateActiveLayers(state, action: PayloadAction<LayerType[]>) {
      state.activeLayers = action.payload;
    },
  },
  extraReducers: {
    [getAllLayers.fulfilled.type]: (state, action: PayloadAction<ILayers>) => {
      state.getLayersLoading = false;
      state.getLayersError = false;
      state.layers = action.payload;
      state.getLayersSuccess = true;
    },
    [getAllLayers.pending.type]: (state) => {
      state.getLayersLoading = true;
    },
    [getAllLayers.rejected.type]: (state, action: PayloadAction) => {
      state.getLayersLoading = false;
      state.getLayersError = true;
      handleReduxError(action.payload);
    },
    [getNonFilteredLayers.fulfilled.type]: (
      state,
      action: PayloadAction<ILayers>
    ) => {
      state.getNonFilteredLayersLoading = false;
      state.getNonFilteredLayersError = false;
      state.nonFilteredLayers = action.payload;
      state.getNonFilteredLayersSuccess = true;
    },
    [getAllLayers.pending.type]: (state) => {
      state.getNonFilteredLayersLoading = true;
    },
    [getAllLayers.rejected.type]: (state, action: PayloadAction) => {
      state.getNonFilteredLayersLoading = false;
      state.getNonFilteredLayersError = true;
      handleReduxError(action.payload);
    },
    [getFilteredLayers.fulfilled.type]: (
      state,
      action: PayloadAction<ILayers>
    ) => {
      state.getFilteredLayersLoading = false;
      state.getFilteredLayersError = false;
      state.filteredLayers = action.payload;
      state.getFilteredLayersSuccess = true;
    },
    [getFilteredLayers.pending.type]: (state) => {
      state.getFilteredLayersLoading = true;
    },
    [getFilteredLayers.rejected.type]: (state, action: PayloadAction) => {
      state.getFilteredLayersLoading = false;
      state.getFilteredLayersError = true;
      handleReduxError(action.payload);
    },
    [createLayer.fulfilled.type]: (state) => {
      state.createLayerLoading = false;
      state.createLayerError = false;
      state.createLayerSuccess = true;
      toast.success("Слой создан успешно!");
    },
    [createLayer.pending.type]: (state) => {
      state.createLayerLoading = true;
    },
    [createLayer.rejected.type]: (state, action: PayloadAction) => {
      state.createLayerLoading = false;
      state.createLayerError = true;
      handleReduxError(action.payload);
    },
    [getSingleLayer.fulfilled.type]: (
      state,
      action: PayloadAction<ILayers>
    ) => {
      state.getSingleLayerLoading = false;
      state.getSingleLayerError = false;
      state.singleLayer = action.payload;
      state.getSingleLayerSuccess = true;
    },
    [getSingleLayer.pending.type]: (state) => {
      state.getSingleLayerLoading = true;
    },
    [getSingleLayer.rejected.type]: (state, action: PayloadAction) => {
      state.getSingleLayerLoading = false;
      state.getSingleLayerError = true;
      handleReduxError(action.payload);
    },
    [editLayer.fulfilled.type]: (state) => {
      state.editLayerLoading = false;
      state.editLayerError = false;
      state.editLayerSuccess = true;
      toast.success("Слой успешно обновлен!");
    },
    [editLayer.pending.type]: (state) => {
      state.editLayerLoading = true;
    },
    [editLayer.rejected.type]: (state, action: PayloadAction) => {
      state.editLayerLoading = false;
      state.editLayerError = true;
      handleReduxError(action.payload);
    },
    [deleteLayer.fulfilled.type]: (state) => {
      state.deleteLayerLoading = false;
      state.deleteLayerError = false;
      state.deleteLayerSuccess = true;
      toast.success("Слой успешно удален!");
    },
    [deleteLayer.pending.type]: (state) => {
      state.deleteLayerLoading = true;
    },
    [deleteLayer.rejected.type]: (state, action: PayloadAction) => {
      state.deleteLayerLoading = false;
      state.deleteLayerError = true;
      handleReduxError(action.payload);
    },
    [createLayerFromFile.fulfilled.type]: (state) => {
      state.createLayerFromFileLoading = false;
      state.createLayerFromFileError = false;
      state.createLayerFromFileSuccess = true;
      toast.success("Файл успешно загружен !");
    },
    [createLayerFromFile.pending.type]: (state) => {
      state.createLayerFromFileLoading = true;
    },
    [createLayerFromFile.rejected.type]: (state, action: PayloadAction) => {
      state.createLayerFromFileLoading = false;
      state.createLayerFromFileError = true;
      handleReduxError(action.payload);
    },
    [getLayersTypes.fulfilled.type]: (
      state,
      action: PayloadAction<ILayers>
    ) => {
      state.getLayersTypesLoading = false;
      state.getLayersTypesError = false;
      state.layersTypes = action.payload;
      state.getLayersTypesSuccess = true;
    },
    [getLayersTypes.pending.type]: (state) => {
      state.getLayersTypesLoading = true;
    },
    [getLayersTypes.rejected.type]: (state, action: PayloadAction) => {
      state.getLayersTypesLoading = false;
      state.getLayersTypesError = true;
      handleReduxError(action.payload);
    },
  },
});

export const { cleanLayers, updateSelectedLayer, updateActiveLayers } =
  layersSlice.actions;

export default layersSlice.reducer;
