import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  WorkspacePermissionList,
  WorkspacePermissionState,
} from "./workspacePermissionInterface";
import { toast } from "react-toastify";
import handleReduxError from "src/shared/utils/handleReduxError";

import {
  deleteWorkspacePermissions,
  editWorkspacePermissions,
  createWorkspacePermissions,
  getWorkspacePermissions,
  getWorkspacePermissionsById,
} from "./workspacePersmissionAction";

const initialState: WorkspacePermissionState = {
  getWorkspacePermissionsLoading: false,
  getWorkspacePermissionsSuccess: false,
  getWorkspacePermissionsError: false,
  allWorkspacePermissions: null,

  createWorkspacePermissionsLoading: false,
  createWorkspacePermissionsSuccess: false,
  createWorkspacePermissionsError: false,

  getWorkspacePermissionsByIdLoading: false,
  getWorkspacePermissionsByIdSuccess: false,
  getWorkspacePermissionsByIdError: false,
  singleWorkspace: {},

  editWorkspacePermissionsLoading: false,
  editWorkspacePermissionsSuccess: false,
  editWorkspacePermissionsError: false,

  deleteWorkspacePermissionsLoading: false,
  deleteWorkspacePermissionsSuccess: false,
  deleteWorkspacePermissionsError: false,
};

const workspacePermissionSlice = createSlice({
  name: "workspacePermissions",
  initialState,
  reducers: {
    cleanWorkspacePermission: (state) => {
      state.createWorkspacePermissionsLoading = false;
      state.createWorkspacePermissionsSuccess = false;
      state.createWorkspacePermissionsError = false;

      state.editWorkspacePermissionsLoading = false;
      state.editWorkspacePermissionsSuccess = false;
      state.editWorkspacePermissionsError = false;

      state.deleteWorkspacePermissionsLoading = false;
      state.deleteWorkspacePermissionsSuccess = false;
      state.deleteWorkspacePermissionsError = false;
    },
  },
  extraReducers: {
    [getWorkspacePermissions.fulfilled.type]: (
      state,
      action: PayloadAction<WorkspacePermissionList>
    ) => {
      state.getWorkspacePermissionsLoading = false;
      state.getWorkspacePermissionsError = false;
      state.allWorkspacePermissions = action.payload;
      state.getWorkspacePermissionsSuccess = true;
    },
    [getWorkspacePermissions.pending.type]: (state) => {
      state.getWorkspacePermissionsLoading = true;
    },
    [getWorkspacePermissions.rejected.type]: (state, action: PayloadAction) => {
      state.getWorkspacePermissionsLoading = false;
      state.getWorkspacePermissionsError = true;
      handleReduxError(action.payload);
    },
    [createWorkspacePermissions.fulfilled.type]: (state) => {
      state.createWorkspacePermissionsLoading = false;
      state.createWorkspacePermissionsError = false;
      state.createWorkspacePermissionsSuccess = true;
      toast.success("Рабочее пространство успешно создано!");
    },
    [createWorkspacePermissions.pending.type]: (state) => {
      state.createWorkspacePermissionsLoading = true;
    },
    [createWorkspacePermissions.rejected.type]: (state, action: PayloadAction) => {
      state.createWorkspacePermissionsLoading = false;
      state.createWorkspacePermissionsError = true;
      handleReduxError(action.payload);
    },

    [getWorkspacePermissionsById.fulfilled.type]: (
      state,
      action: PayloadAction<WorkspacePermissionList>
    ) => {
      state.getWorkspacePermissionsByIdLoading = false;
      state.getWorkspacePermissionsByIdError = false;
      state.singleWorkspace = action.payload;
      state.getWorkspacePermissionsByIdSuccess = true;
    },
    [getWorkspacePermissionsById.pending.type]: (state) => {
      state.getWorkspacePermissionsByIdLoading = true;
    },
    [getWorkspacePermissionsById.rejected.type]: (state, action: PayloadAction) => {
      state.getWorkspacePermissionsByIdLoading = false;
      state.getWorkspacePermissionsByIdError = true;
      handleReduxError(action.payload);
    },
    [editWorkspacePermissions.fulfilled.type]: (state) => {
      state.editWorkspacePermissionsLoading = false;
      state.editWorkspacePermissionsError = false;
      state.editWorkspacePermissionsSuccess = true;
      toast.success("Рабочее пространство успешно обновлено!");
    },
    [editWorkspacePermissions.pending.type]: (state) => {
      state.editWorkspacePermissionsLoading = true;
    },
    [editWorkspacePermissions.rejected.type]: (
      state,
      action: PayloadAction
    ) => {
      state.editWorkspacePermissionsLoading = false;
      state.editWorkspacePermissionsError = true;
      handleReduxError(action.payload);
    },
    [deleteWorkspacePermissions.fulfilled.type]: (state) => {
      state.deleteWorkspacePermissionsLoading = false;
      state.deleteWorkspacePermissionsError = false;
      state.deleteWorkspacePermissionsSuccess = true;
      toast.success("Рабочее пространство успешно удалено!");
    },
    [deleteWorkspacePermissions.pending.type]: (state) => {
      state.deleteWorkspacePermissionsLoading = true;
    },
    [deleteWorkspacePermissions.rejected.type]: (state, action: PayloadAction) => {
      state.deleteWorkspacePermissionsLoading = false;
      state.deleteWorkspacePermissionsError = true;
      handleReduxError(action.payload);
    },
  },
});

export const { cleanWorkspacePermission } = workspacePermissionSlice.actions;

export default workspacePermissionSlice.reducer;
