import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICircleTools } from '../../../models/ICircleTools';
import { ICircleToolsState } from './circleToolsInterface';
import { toast } from 'react-toastify';
import handleReduxError from "src/shared/utils/handleReduxError";

import {
  getAllCircleTools,
  createCircleTools, 
  getSingleCircleTools, 
  editCircleTools,
  deleteCircleTools 
} from './circleToolsAction';

const initialState:ICircleToolsState = {
  getAllCircleToolsSuccess: false,
  getAllCircleToolsLoading: false,
  getAllCircleToolsError: false,
  allCircleTools: {},

  createCircleToolsSuccess: false,
  createCircleToolsLoading: false,
  createCircleToolsError: false,
  createdCircleTools: {},

  getSingleCircleToolsSuccess: false,
  getSingleCircleToolsLoading: false,
  getSingleCircleToolsError: false,
  singleCircleTools: {},

  editCircleToolsSuccess: false,
  editCircleToolsLoading: false,
  editCircleToolsError: false,

  deleteCircleToolsSuccess: false,
  deleteCircleToolsLoading: false,
  deleteCircleToolsError: false
};

const circleToolsSlice = createSlice({
  name: 'circle-tools',
  initialState,
  reducers: {
    cleanCircleTools: (state) => {
      state.createCircleToolsSuccess=false;
      state.createCircleToolsLoading=false;
      state.createCircleToolsError=false;
      state.createdCircleTools={};

      state.editCircleToolsSuccess=false;
      state.editCircleToolsLoading=false;
      state.editCircleToolsError=false;

      state.deleteCircleToolsSuccess=false;
      state.deleteCircleToolsLoading=false;
      state.deleteCircleToolsError=false;
    },
  },
  extraReducers: {
    [getAllCircleTools.fulfilled.type]: (state, action: PayloadAction<ICircleTools>) => {
      state.getAllCircleToolsLoading = false;
      state.getAllCircleToolsError = false;
      state.allCircleTools = action.payload;
      state.getAllCircleToolsSuccess = true;
    },
    [getAllCircleTools.pending.type]: (state) => {
      state.getAllCircleToolsLoading = true;
    },
    [getAllCircleTools.rejected.type]: (state, action: PayloadAction) => {
      state.getAllCircleToolsLoading = false;
      state.getAllCircleToolsError = true;
      handleReduxError(action.payload);
    },
    [createCircleTools.fulfilled.type]: (state, action: PayloadAction<ICircleTools>) => {
      state.createCircleToolsLoading = false;
      state.createCircleToolsError = false;
      state.createCircleToolsSuccess = true;
      state.createdCircleTools = action.payload;
      state.deleteCircleToolsSuccess = false;
      toast.success('Круговые инструменты успешно созданы!');
    },
    [createCircleTools.pending.type]: (state) => {
      state.createCircleToolsLoading = true;
    },
    [createCircleTools.rejected.type]: (state, action: PayloadAction) => {
      state.createCircleToolsLoading = false;
      state.createCircleToolsError = true;
      handleReduxError(action.payload);
    },
    [getSingleCircleTools.fulfilled.type]: (state, action: PayloadAction<ICircleTools>) => {
      state.getSingleCircleToolsLoading = false;
      state.getSingleCircleToolsError = false;
      state.singleCircleTools = action.payload;
      state.getSingleCircleToolsSuccess = true;
    },
    [getSingleCircleTools.pending.type]: (state) => {
      state.getSingleCircleToolsLoading = true;
    },
    [getSingleCircleTools.rejected.type]: (state, action: PayloadAction) => {
      state.getSingleCircleToolsLoading = false;
      state.getSingleCircleToolsError = true;
      handleReduxError(action.payload);
    },
    [editCircleTools.fulfilled.type]: (state) => {
      state.editCircleToolsLoading = false;
      state.editCircleToolsError = false;
      state.editCircleToolsSuccess = true;
      toast.success('Инструменты круга успешно изменены!');
    },
    [editCircleTools.pending.type]: (state) => {
      state.editCircleToolsLoading = true;
    },
    [editCircleTools.rejected.type]: (state, action: PayloadAction) => {
      state.editCircleToolsLoading = false;
      state.editCircleToolsError = true;
      handleReduxError(action.payload);
    },
    [deleteCircleTools.fulfilled.type]: (state) => {
      state.deleteCircleToolsLoading = false;
      state.deleteCircleToolsError = false;
      state.deleteCircleToolsSuccess = true;
    },
    [deleteCircleTools.pending.type]: (state) => {
      state.deleteCircleToolsLoading = true;
    },
    [deleteCircleTools.rejected.type]: (state, action: PayloadAction) => {
      state.deleteCircleToolsLoading = false;
      state.deleteCircleToolsError = true;
      handleReduxError(action.payload);
    }
  }
});

export const { cleanCircleTools } = circleToolsSlice.actions;

export default circleToolsSlice.reducer;