import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "src/configs";
import {
  IGraphVertices,
  IEditGraphVertices,
  ICreateGraphVertices,
} from "src/models/IGraphVertices";

export const getAllGraphVertices = createAsyncThunk(
  "get-all-graphs-vertices",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<IGraphVertices>(
        `${BASE_URL}/graph-vertices`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const getSingleGraphVertice = createAsyncThunk(
  "get-single-graph-vertice",
  async (id: string | undefined, thunkAPI) => {
    try {
      const response = await axios.get<IGraphVertices>(
        `${BASE_URL}/graph-vertices/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const editGraphVertices = createAsyncThunk(
  "edit-graph-vertices",
  async (data: IEditGraphVertices, thunkAPI) => {
    try {
      const response = await axios.put<IEditGraphVertices>(
        `${BASE_URL}/graph-vertices/${data.id}`,
        data,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const createGraphVertices = createAsyncThunk(
  "create-graph-vertices",
  async (data: ICreateGraphVertices, thunkAPI) => {
    try {
      const response = await axios.post<ICreateGraphVertices>(
        `${BASE_URL}/graph-vertices`,
        data,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const deleteGraphVertices = createAsyncThunk(
  "delete-graph-vertices",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.delete(`${BASE_URL}/graph-vertices/${id}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);
