import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGraphsState } from "./graphsInterface";
import { IGraphs } from "src/models/IGraphs";
import { toast } from "react-toastify";
import handleReduxError from "src/shared/utils/handleReduxError";

import {
  getAllGraphs,
  getAllFilteredGraphs,
  getSingleGraph,
  createGraphs,
  editGraphs,
  deleteGraphs
 } from "./graphsAction";

const initialState: IGraphsState = {
  createGraphsLoading: false,
  createGraphsSuccess: false,
  createGraphsError: false,
  createdGraphs: {},

  getAllGraphsLoading: false,
  getAllGraphsSuccess: false,
  getAllGraphsError: false,
  allGraphs: {},

  getAllFilteredGraphsLoading: false,
  getAllFilteredGraphsSuccess: false,
  getAllFilteredGraphsError: false,
  allFilteredGraphs: {},

  getSingleGraphLoading: false,
  getSingleGraphSuccess: false,
  getSingleGraphError: false,
  singleGraph: {},

  editGraphsLoading: false,
  editGraphsSuccess: false,
  editGraphsError: false,

  deleteGraphsLoading: false,
  deleteGraphsSuccess: false,
  deleteGraphsError: false,
};

const GraphsSlice = createSlice({
  name: "Graphs",
  initialState,
  reducers: {
    cleanGraphs: (state) => {
      state.createGraphsLoading=false;
      state.createGraphsSuccess=false;
      state.createGraphsError=false;

      state.editGraphsLoading=false;
      state.editGraphsSuccess=false;
      state.editGraphsError=false;

      state.deleteGraphsLoading=false;
      state.deleteGraphsSuccess=false;
      state.deleteGraphsError=false;

      state.getSingleGraphLoading=false;
      state.getSingleGraphSuccess=false;
      state.getSingleGraphError=false;
    }
  },
  extraReducers: {
    [getAllGraphs.fulfilled.type]: (state, action: PayloadAction<IGraphs>) => {
      state.getAllGraphsLoading = false;
      state.getAllGraphsError = false;
      state.getAllGraphsSuccess = true;
      state.allGraphs = action.payload;
    },
    [getAllGraphs.pending.type]: (state) => {
      state.getAllGraphsLoading = true;
    },
    [getAllGraphs.rejected.type]: (state, action: PayloadAction) => {
      state.getAllGraphsLoading = false;
      state.getAllGraphsError = true;
      handleReduxError(action.payload);
    },
    [getAllFilteredGraphs.fulfilled.type]: (state, action: PayloadAction<IGraphs>) => {
      state.getAllFilteredGraphsLoading = false;
      state.getAllFilteredGraphsError = false;
      state.getAllFilteredGraphsSuccess = true;
      state.allFilteredGraphs = action.payload;
    },
    [getAllFilteredGraphs.pending.type]: (state) => {
      state.getAllFilteredGraphsLoading = true;
    },
    [getAllFilteredGraphs.rejected.type]: (state, action: PayloadAction) => {
      state.getAllFilteredGraphsLoading = false;
      state.getAllFilteredGraphsError = true;
      handleReduxError(action.payload);
    },
    [getSingleGraph.fulfilled.type]: (state, action: PayloadAction<IGraphs>) => {
      state.getSingleGraphLoading = false;
      state.getSingleGraphError = false;
      state.getSingleGraphSuccess = true;
      state.singleGraph = action.payload;
    },
    [getSingleGraph.pending.type]: (state) => {
      state.getSingleGraphLoading = true;
    },
    [getSingleGraph.rejected.type]: (state, action: PayloadAction) => {
      state.getSingleGraphLoading = false;
      state.getSingleGraphError = true;
      handleReduxError(action.payload);
    },
    [createGraphs.fulfilled.type]: (state, action: PayloadAction<IGraphs>) => {
      state.createGraphsLoading = false;
      state.createGraphsError = false;
      state.createGraphsSuccess = true;
      state.createdGraphs = action.payload;
      state.deleteGraphsSuccess = false;
      toast.success('Граф создан успешно!');
    },
    [createGraphs.pending.type]: (state) => {
      state.createGraphsLoading = true;
    },
    [createGraphs.rejected.type]: (state, action: PayloadAction) => {
      state.createGraphsLoading = false;
      state.createGraphsError = true;
      handleReduxError(action.payload);
    },
    [editGraphs.fulfilled.type]: (state) => {
      state.editGraphsLoading = false;
      state.editGraphsError = false;
      state.editGraphsSuccess = true;
      toast.success('Граф успешно отредактирован!');
    },
    [editGraphs.pending.type]: (state) => {
      state.editGraphsLoading = true;
    },
    [editGraphs.rejected.type]: (state, action: PayloadAction) => {
      state.editGraphsLoading = false;
      state.editGraphsError = true;
      handleReduxError(action.payload);
    },
    [deleteGraphs.fulfilled.type]: (state) => {
      state.deleteGraphsLoading = false;
      state.deleteGraphsError = false;
      state.deleteGraphsSuccess = true;
    },
    [deleteGraphs.pending.type]: (state) => {
      state.deleteGraphsLoading = true;
    },
    [deleteGraphs.rejected.type]: (state, action: PayloadAction) => {
      state.deleteGraphsLoading = false;
      state.deleteGraphsError = true;
      handleReduxError(action.payload);
    },
  },

});

export const { cleanGraphs } = GraphsSlice.actions;

export default GraphsSlice.reducer;
