import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILineStrings } from 'src/models/ILineStrings';
import { ILineStringsState } from './lineStringsInterface';
import { toast } from 'react-toastify';
import handleReduxError from "src/shared/utils/handleReduxError";

import {
  getAllLineStrings, 
  createLineStrings, 
  getSingleLineStrings, 
  editLineStrings, 
  deleteLineStrings 
} from './lineStringsAction';

const initialState:ILineStringsState = {
  getAllLineStringsSuccess: false,
  getAllLineStringsError: false,
  getAllLineStringsLoading: false,
  allLineStrings: {},

  createLineStringsSuccess: false,
  createLineStringsError: false,
  createLineStringsLoading: false,
  createdLineStrings: {},

  getSingleLineStringsSuccess: false,
  getSingleLineStringsError: false,
  getSingleLineStringsLoading: false,
  singleLineStrings: {},

  editLineStringsSuccess: false,
  editLineStringsError: false,
  editLineStringsLoading: false,

  deleteLineStringsSuccess: false,
  deleteLineStringsError: false,
  deleteLineStringsLoading: false
};

const lineStringsSlice = createSlice({
  name: 'lineStrings',
  initialState,
  reducers: {
    cleanLineStrings: (state) => {
    state.createLineStringsSuccess= false;
    state.createLineStringsError= false;
    state.createLineStringsLoading= false;
    state.createdLineStrings= {};

    state.getSingleLineStringsSuccess= false;
    state.getSingleLineStringsError= false;
    state.getSingleLineStringsLoading= false;
    state.singleLineStrings= {};

    state.editLineStringsSuccess= false;
    state.editLineStringsError= false;
    state.editLineStringsLoading= false;

    state.deleteLineStringsSuccess= false;
    state.deleteLineStringsError= false;
    state.deleteLineStringsLoading= false;
    }
  },
  extraReducers: {
    [getAllLineStrings.fulfilled.type]: (state, action: PayloadAction<ILineStrings>) => {
      state.getAllLineStringsLoading = false;
      state.getAllLineStringsError = false;
      state.allLineStrings = action.payload;
      state.getAllLineStringsSuccess = true;
    },
    [getAllLineStrings.pending.type]: (state) => {
      state.getAllLineStringsLoading = true;
    },
    [getAllLineStrings.rejected.type]: (state, action: PayloadAction) => {
      state.getAllLineStringsLoading = false;
      state.getAllLineStringsError = true;
      handleReduxError(action.payload);
    },
    [createLineStrings.fulfilled.type]: (state, action: PayloadAction<ILineStrings>) => {
      state.createLineStringsLoading = false;
      state.createLineStringsError = false;
      state.createLineStringsSuccess = true;
      state.createdLineStrings = action.payload;
      toast.success('Линии созданы успешно!');
    },
    [createLineStrings.pending.type]: (state) => {
      state.createLineStringsLoading = true;
    },
    [createLineStrings.rejected.type]: (state, action: PayloadAction) => {
      state.createLineStringsLoading = false;
      state.createLineStringsError = true;
      handleReduxError(action.payload);
    },
    [getSingleLineStrings.fulfilled.type]: (state, action: PayloadAction<ILineStrings>) => {
      state.getSingleLineStringsLoading = false;
      state.getSingleLineStringsError = false;
      state.singleLineStrings = action.payload;
      state.getSingleLineStringsSuccess = true;
    },
    [getSingleLineStrings.pending.type]: (state) => {
      state.getSingleLineStringsLoading = true;
    },
    [getSingleLineStrings.rejected.type]: (state, action: PayloadAction) => {
      state.getSingleLineStringsLoading = false;
      state.getSingleLineStringsError = true;
      handleReduxError(action.payload);
    },
    [editLineStrings.fulfilled.type]: (state) => {
      state.editLineStringsLoading = false;
      state.editLineStringsError = false;
      state.editLineStringsSuccess = true;
      toast.success('Линии успешно отредактированы!');
    },
    [editLineStrings.pending.type]: (state) => {
      state.editLineStringsLoading = true;
    },
    [editLineStrings.rejected.type]: (state, action: PayloadAction) => {
      state.editLineStringsLoading = false;
      state.editLineStringsError = true;
      handleReduxError(action.payload);
      toast.error('Не удалось отредактировать линии!');
    },
    [deleteLineStrings.fulfilled.type]: (state) => {
      state.deleteLineStringsLoading = false;
      state.deleteLineStringsError = false;
      state.deleteLineStringsSuccess = true;
    },
    [deleteLineStrings.pending.type]: (state) => {
      state.deleteLineStringsLoading = true;
    },
    [deleteLineStrings.rejected.type]: (state, action: PayloadAction) => {
      state.deleteLineStringsLoading = false;
      state.deleteLineStringsError = true;
      handleReduxError(action.payload);
    },
  }
});

export const { cleanLineStrings } = lineStringsSlice.actions;

export default lineStringsSlice.reducer;