import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userSlice from "../features/users/userSlice";
import workspaceSlice from "../features/workspaces/workspacesSlice";
import layerGroupSlice from "../features/layerGroups/layerGroupsSlice";
import layersSlice from "../features/layers/layersSlice";
import placemarksSlice from "../features/placemarks/placemarksSlice";
import placemarkColumnGroupSlice from "../features/placemarkColumnGroups/placemarkColumnGroupsSlice";
import polygonsSlice from "../features/polygons/polygonsSlice";
import polygonToolsSlice from "../features/polygonTools/polygonToolsSlice";
import circleToolsSlice from "../features/circleTools/circleToolsSlice";
import lineStringsSlice from "../features/lineStrings/lineStringsSlice";
import isochroneToolsSlice from "../features/isochroneTools/isochroneToolsSlice";
import cascadeToolsSlice from "../features/cascadeTools/cascadeToolsSlice";
import graphsSlice from "../features/graphs/graphsSlice";
import gridsSlice from "../features/grids/gridsSlice";
import graphVerticesSlice from "../features/graphVertices/graphVerticesSlice";
import usersRoleSlice from "../features/usersRole/userRoleSlice";
import rolesSlice from "../features/roles/rolesSlice";
import workspacePermissionSlice from "../features/workspacePermission/workspacePermissionSlice";
import layerPermissionSlice from "../features/layerPermission/layerPermissionSlice";
import graphVertexColumnsSlice from "../features/graphVertexColumns/graphVertexColumnsSlice";
import polygonColumnsSlice from "../features/polygonColumns/polygonColumnsSlice";
import placemarkColumnsSlice from "../features/placemarkColumns/placemarkColumnsSlice";
import polygonColumnGroupsSlice from "../features/polygonColumnGroups/polygonColumnGroupsSlice";
import graphVertexColumnGroupsSlice from "../features/graphVertexColumnGroups/graphVertexColumnGroupsSlice";
import placemarkModalSlice from "../features/placemarkModal";

const RootReducer = combineReducers({
  userSlice,
  workspaceSlice,
  workspacePermissionSlice,
  layerGroupSlice,
  layersSlice,
  layerPermissionSlice,
  placemarksSlice,
  placemarkColumnGroupSlice,
  polygonsSlice,
  polygonToolsSlice,
  circleToolsSlice,
  lineStringsSlice,
  isochroneToolsSlice,
  cascadeToolsSlice,
  graphsSlice,
  gridsSlice,
  graphVerticesSlice,
  usersRoleSlice,
  rolesSlice,
  graphVertexColumnsSlice,
  polygonColumnsSlice,
  placemarkColumnsSlice,
  polygonColumnGroupsSlice,
  graphVertexColumnGroupsSlice,
  placemarkModalSlice,
});

export const store = configureStore({
  reducer: { RootReducer },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
