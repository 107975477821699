import axios from 'axios';
export const BASE_URL = process.env.REACT_APP_API_KEY;

export default axios.create({ baseURL: BASE_URL });

export const test = 1;

console.log('BASE_URL', BASE_URL)

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true
});