import axios from "axios";
import {
  ILayerGroups,
  ICreateLayerGroup,
  IEditLayerGroup,
} from "src/models/ILayerGroups";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "src/configs";

export const getAllLayerGroups = createAsyncThunk(
  "get-layer-groups",
  async (id: string | null | undefined, thunkAPI) => {
    try {
      const response = await axios.get<ILayerGroups>(
        `${BASE_URL}/layer-groups`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          params: {
            "filter[workspace-uuid]": id,
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const createLayerGroup = createAsyncThunk(
  "create-layer-group",
  async (formData: ICreateLayerGroup, thunkAPI) => {
    try {
      const response = await axios.post<ICreateLayerGroup>(
        `${BASE_URL}/layer-groups`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const getSingleLayerGroup = createAsyncThunk(
  "get-single-layer-group",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.get<ILayerGroups>(
        `${BASE_URL}/layer-groups/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const editLayerGroup = createAsyncThunk(
  "edit-layer-group",
  async (formData: IEditLayerGroup, thunkAPI) => {
    try {
      const response = await axios.put<ILayerGroups>(
        `${BASE_URL}/layer-groups/${formData.id}`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const deleteLayerGroup = createAsyncThunk(
  "delete-layer-group",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.delete<ILayerGroups>(
        `${BASE_URL}/layer-groups/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);
