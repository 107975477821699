import axios from "axios";
import {
  IPlacemarkColumnGroups,
  IEditPlacemarkColumnGroups,
  ICreatePlacemarkColumnGroups
} from "src/models/IPlacemarkColumnGroups";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "src/configs";

export const getPlacemarkColumnGroups = createAsyncThunk(
  "get-placemark-column-groups",
  async (id: string | undefined, thunkAPI) => {
    try {
      const response = await axios.get<IPlacemarkColumnGroups>(
        `${BASE_URL}/placemark-column-groups`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          params: {
            "filter[placemark-uuid]": id,
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const createPlacemarkColumnGroups = createAsyncThunk(
  "create-placemark-column-groups",
  async (data: ICreatePlacemarkColumnGroups, thunkAPI) => {
    try {
      const response = await axios.post<ICreatePlacemarkColumnGroups>(
        `${BASE_URL}/placemark-column-groups`,
        data,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const getSinglePlacemarkColumnGroup = createAsyncThunk(
  "get-single-placemark-column-group",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.get<IPlacemarkColumnGroups>(
        `${BASE_URL}/placemark-column-groups/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const editPlacemarkColumnGroups = createAsyncThunk(
  "edit-placemark-column-groups",
  async (formData: IEditPlacemarkColumnGroups, thunkAPI) => {
    try {
      const response = await axios.put<IEditPlacemarkColumnGroups>(
        `${BASE_URL}/placemark-column-groups/${formData.id}`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const deletePlacemarkColumnGroups = createAsyncThunk(
  "delete-placemark-column-groups",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.delete<IPlacemarkColumnGroups>(
        `${BASE_URL}/placemark-column-groups/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);
