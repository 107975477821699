import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGraphVerticesState } from "./graphVerticesInterface";
import { IGraphVertices } from "src/models/IGraphVertices";
import { toast } from "react-toastify";
import handleReduxError from "src/shared/utils/handleReduxError";

import {
  getAllGraphVertices,
  getSingleGraphVertice,
  createGraphVertices,
  editGraphVertices,
  deleteGraphVertices
 } from "./graphVerticesAction";

const initialState: IGraphVerticesState = {
  createGraphVerticesLoading: false,
  createGraphVerticesSuccess: false,
  createGraphVerticesError: false,
  createdGraphVertices: {},

  getAllGraphVerticesLoading: false,
  getAllGraphVerticesSuccess: false,
  getAllGraphVerticesError: false,
  allGraphVertices: {},

  getAllFilteredGraphVerticesLoading: false,
  getAllFilteredGraphVerticesSuccess: false,
  getAllFilteredGraphVerticesError: false,
  allFilteredGraphVertices: {},

  getSingleGraphVerticeLoading: false,
  getSingleGraphVerticeSuccess: false,
  getSingleGraphVerticeError: false,
  singleGraphVertice: {},

  editGraphVerticesLoading: false,
  editGraphVerticesSuccess: false,
  editGraphVerticesError: false,

  deleteGraphVerticesLoading: false,
  deleteGraphVerticesSuccess: false,
  deleteGraphVerticesError: false,
};

const graphVerticesSlice = createSlice({
  name: "Graph-Vertices",
  initialState,
  reducers: {
    cleanGraphVertices: (state) => {
      state.createGraphVerticesLoading=false;
      state.createGraphVerticesSuccess=false;
      state.createGraphVerticesError=false;

      state.editGraphVerticesLoading=false;
      state.editGraphVerticesSuccess=false;
      state.editGraphVerticesError=false;

      state.deleteGraphVerticesLoading=false;
      state.deleteGraphVerticesSuccess=false;
      state.deleteGraphVerticesError=false;

      state.getSingleGraphVerticeLoading=false;
      state.getSingleGraphVerticeSuccess=false;
      state.getSingleGraphVerticeError=false;
      state.singleGraphVertice={};
    }
  },
  extraReducers: {
    [getAllGraphVertices.fulfilled.type]: (state, action: PayloadAction<IGraphVertices>) => {
      state.getAllGraphVerticesLoading = false;
      state.getAllGraphVerticesError = false;
      state.getAllGraphVerticesSuccess = true;
      state.allGraphVertices = action.payload;
    },
    [getAllGraphVertices.pending.type]: (state) => {
      state.getAllGraphVerticesLoading = true;
    },
    [getAllGraphVertices.rejected.type]: (state, action: PayloadAction) => {
      state.getAllGraphVerticesLoading = false;
      state.getAllGraphVerticesError = true;
      handleReduxError(action.payload);
    },
    [getSingleGraphVertice.fulfilled.type]: (state, action: PayloadAction<IGraphVertices>) => {
      state.getSingleGraphVerticeLoading = false;
      state.getSingleGraphVerticeError = false;
      state.getSingleGraphVerticeSuccess = true;
      state.singleGraphVertice = action.payload;
    },
    [getSingleGraphVertice.pending.type]: (state) => {
      state.getSingleGraphVerticeLoading = true;
    },
    [getSingleGraphVertice.rejected.type]: (state, action: PayloadAction) => {
      state.getSingleGraphVerticeLoading = false;
      state.getSingleGraphVerticeError = true;
      handleReduxError(action.payload);
    },
    [createGraphVertices.fulfilled.type]: (state, action: PayloadAction<IGraphVertices>) => {
      state.createGraphVerticesLoading = false;
      state.createGraphVerticesError = false;
      state.createGraphVerticesSuccess = true;
      state.createdGraphVertices = action.payload;
      state.deleteGraphVerticesSuccess = false;
      toast.success('Граф создан успешно!');
    },
    [createGraphVertices.pending.type]: (state) => {
      state.createGraphVerticesLoading = true;
    },
    [createGraphVertices.rejected.type]: (state, action: PayloadAction) => {
      state.createGraphVerticesLoading = false;
      state.createGraphVerticesError = true;
      handleReduxError(action.payload);
    },
    [editGraphVertices.fulfilled.type]: (state) => {
      state.editGraphVerticesLoading = false;
      state.editGraphVerticesError = false;
      state.editGraphVerticesSuccess = true;
    },
    [editGraphVertices.pending.type]: (state) => {
      state.editGraphVerticesLoading = true;
    },
    [editGraphVertices.rejected.type]: (state, action: PayloadAction) => {
      state.editGraphVerticesLoading = false;
      state.editGraphVerticesError = true;
      handleReduxError(action.payload);
    },
    [deleteGraphVertices.fulfilled.type]: (state) => {
      state.deleteGraphVerticesLoading = false;
      state.deleteGraphVerticesError = false;
      state.deleteGraphVerticesSuccess = true;
    },
    [deleteGraphVertices.pending.type]: (state) => {
      state.deleteGraphVerticesLoading = true;
    },
    [deleteGraphVertices.rejected.type]: (state, action: PayloadAction) => {
      state.deleteGraphVerticesLoading = false;
      state.deleteGraphVerticesError = true;
      handleReduxError(action.payload);
    },
  },

});

export const { cleanGraphVertices } = graphVerticesSlice.actions;

export default graphVerticesSlice.reducer;
