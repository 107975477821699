import { Component } from 'react';

import {
  Container, 
  TextWrapper, 
  ComponentStyled, 
  BackButton 
} from './styles';

import ErrorIcon from '../../assets/images/broken.svg';
import { BASE_URL } from 'src/configs';

interface State {
  hasError: boolean;
};

interface Props {
  navigate: any;
};

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
      super(props);

      this.state = {
        hasError: false
      };
    }

    componentDidCatch() {
      this.setState({hasError: true});
    }

    render() {
      const { navigate } = this.props;
      if (this.state.hasError) {
        return (
          <Container>
            <ComponentStyled>
              <img src={ErrorIcon} alt="error-icon"/>
              <TextWrapper>
                <span>Веб-сайт не работает.</span>
                <h1>Пожалуйста, cообщите об этом в центр поддержки разработчиков!</h1>
              </TextWrapper>
            </ComponentStyled>
            <BackButton onClick={() => navigate(-1)}>
              <a href={`${BASE_URL}/workspace`}>Hазад</a>
            </BackButton>
          </Container>
        )
      }
      return this.props.children;
    }
};


export default ErrorBoundary;