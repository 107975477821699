import axios from "axios";
import {
  ICascadeTools,
  IAttributes,
  IEditCascadeTools,
} from "../../../models/ICascadeTools";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "src/configs";

export const getAllCascadeTools = createAsyncThunk(
  "get-all-cascade-tools",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<ICascadeTools>(
        `${BASE_URL}/cascade-tools`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const createCascadeTools = createAsyncThunk(
  "create-cascade-tools",
  async (formData: IAttributes, thunkAPI) => {
    try {
      const response = await axios.post<ICascadeTools>(
        `${BASE_URL}/cascade-tools`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const getSingleCascadeTools = createAsyncThunk(
  "get-single-cascade-tools",
  async (id: string | undefined, thunkAPI) => {
    try {
      const response = await axios.get<ICascadeTools>(
        `${BASE_URL}/cascade-tools/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const editCascadeTools = createAsyncThunk(
  "edit-Cascade-tools",
  async (formData: IEditCascadeTools, thunkAPI) => {
    try {
      const response = await axios.put<IEditCascadeTools>(
        `${BASE_URL}/cascade-tools/${formData.id}`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const deleteCascadeTools = createAsyncThunk(
  "delete-cascade-tools",
  async (id: string | undefined, thunkAPI) => {
    try {
      const response = await axios.delete<ICascadeTools>(
        `${BASE_URL}/cascade-tools/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);