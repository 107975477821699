import axios from "axios";
import {
  IGraphVertexColumnGroups,
  IEditGraphVertexColumnGroups,
  ICreateGraphVertexColumnGroups
} from "src/models/IGraphVertexColumnGroups";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "src/configs";

export const getGraphVertexColumnGroups = createAsyncThunk(
  "get-graph-vertex-column-groups",
  async (id: string | undefined, thunkAPI) => {
    try {
      const response = await axios.get<IGraphVertexColumnGroups>(
        `${BASE_URL}/graph-vertice-column-groups`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          params: {
            "filter[graph-vertices-uuid]": id,
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const createGraphVertexColumnGroups = createAsyncThunk(
  "create-graph-vertex-column-groups",
  async (data: ICreateGraphVertexColumnGroups, thunkAPI) => {
    try {
      const response = await axios.post<ICreateGraphVertexColumnGroups>(
        `${BASE_URL}/graph-vertice-column-groups`,
        data,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const getSingleGraphVertexColumnGroup = createAsyncThunk(
  "get-single-graph-vertex-column-group",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.get<IGraphVertexColumnGroups>(
        `${BASE_URL}/graph-vertice-column-groups/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const editGraphVertexColumnGroups = createAsyncThunk(
  "edit-graph-vertex-column-groups",
  async (formData: IEditGraphVertexColumnGroups, thunkAPI) => {
    try {
      const response = await axios.put<IEditGraphVertexColumnGroups>(
        `${BASE_URL}/graph-vertice-column-groups/${formData.id}`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const deleteGraphVertexColumnGroups = createAsyncThunk(
  "delete-graph-vertex-column-groups",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.delete<IGraphVertexColumnGroups>(
        `${BASE_URL}/graph-vertice-column-groups/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);
