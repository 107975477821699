import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IPlacemarkColumns,
  IPlacemarkColumnsType,
  IPlacemarkColumnTitles,
  IPlacemarkColumnValues,
} from "src/models/IPlacemarkColumns";
import { IPlacemarkColumnsState } from "./placemarkColumnsInterface";
import { toast } from "react-toastify";
import handleReduxError from "src/shared/utils/handleReduxError";

import {
  getPlacemarkColumns,
  createPlacemarkColumns,
  getSinglePlacemarkColumn,
  editPlacemarkColumns,
  deletePlacemarkColumns,
  getPlacemarkColumnsType,
  getPlacemarkColumnTitles,
  getPlacemarkColumnValues,
} from "./placemarkColumnsAction";

const initialState: IPlacemarkColumnsState = {
  getPlacemarkColumnsSuccess: false,
  getPlacemarkColumnsLoading: false,
  getPlacemarkColumnsError: false,
  placemarkColumns: {},

  getPlacemarkColumnTitlesSuccess: false,
  getPlacemarkColumnTitlesLoading: false,
  getPlacemarkColumnTitlesError: false,
  placemarkColumnTitles: {},

  getPlacemarkColumnValuesSuccess: false,
  getPlacemarkColumnValuesLoading: false,
  getPlacemarkColumnValuesError: false,
  placemarkColumnValues: {},

  getPlacemarkColumnsTypeSuccess: false,
  getPlacemarkColumnsTypeError: false,
  getPlacemarkColumnsTypeLoading: false,
  placemarkColumnsType: {},

  createPlacemarkColumnsSuccess: false,
  createPlacemarkColumnsLoading: false,
  createPlacemarkColumnsError: false,

  getSinglePlacemarkColumnSuccess: false,
  getSinglePlacemarkColumnLoading: false,
  getSinglePlacemarkColumnError: false,
  singlePlacemarkColumn: {},

  editPlacemarkColumnsSuccess: false,
  editPlacemarkColumnsLoading: false,
  editPlacemarkColumnsError: false,

  deletePlacemarkColumnsSuccess: false,
  deletePlacemarkColumnsLoading: false,
  deletePlacemarkColumnsError: false,
};

const placemarkColumnsSlice = createSlice({
  name: "placemarkColumns",
  initialState,
  reducers: {
    cleanPlacemarkColumns: (state) => {
      state.createPlacemarkColumnsSuccess = false;
      state.createPlacemarkColumnsLoading = false;
      state.createPlacemarkColumnsError = false;

      state.editPlacemarkColumnsSuccess = false;
      state.editPlacemarkColumnsLoading = false;
      state.editPlacemarkColumnsError = false;

      state.deletePlacemarkColumnsSuccess = false;
      state.deletePlacemarkColumnsLoading = false;
      state.deletePlacemarkColumnsError = false;
    },
  },
  extraReducers: {
    [getPlacemarkColumns.fulfilled.type]: (
      state,
      action: PayloadAction<IPlacemarkColumns>
    ) => {
      state.getPlacemarkColumnsLoading = false;
      state.getPlacemarkColumnsError = false;
      state.placemarkColumns = action.payload;
      state.getPlacemarkColumnsSuccess = true;
    },
    [getPlacemarkColumns.pending.type]: (state) => {
      state.getPlacemarkColumnsLoading = true;
    },
    [getPlacemarkColumns.rejected.type]: (state, action: PayloadAction) => {
      state.getPlacemarkColumnsLoading = false;
      state.getPlacemarkColumnsError = true;
      handleReduxError(action.payload);
    },
    [getPlacemarkColumnTitles.fulfilled.type]: (
      state,
      action: PayloadAction<IPlacemarkColumnTitles>
    ) => {
      state.getPlacemarkColumnTitlesLoading = false;
      state.getPlacemarkColumnTitlesError = false;
      state.placemarkColumnTitles = action.payload;
      state.getPlacemarkColumnTitlesSuccess = true;
    },
    [getPlacemarkColumnTitles.pending.type]: (state) => {
      state.getPlacemarkColumnTitlesLoading = true;
    },
    [getPlacemarkColumnTitles.rejected.type]: (
      state,
      action: PayloadAction
    ) => {
      state.getPlacemarkColumnTitlesLoading = false;
      state.getPlacemarkColumnTitlesError = true;
      handleReduxError(action.payload);
    },
    [getPlacemarkColumnValues.fulfilled.type]: (
      state,
      action: PayloadAction<IPlacemarkColumnValues>
    ) => {
      state.getPlacemarkColumnValuesLoading = false;
      state.getPlacemarkColumnValuesError = false;
      state.placemarkColumnValues = action.payload;
      state.getPlacemarkColumnValuesSuccess = true;
    },
    [getPlacemarkColumnValues.pending.type]: (state) => {
      state.getPlacemarkColumnValuesLoading = true;
    },
    [getPlacemarkColumnValues.rejected.type]: (
      state,
      action: PayloadAction
    ) => {
      state.getPlacemarkColumnValuesLoading = false;
      state.getPlacemarkColumnValuesError = true;
      handleReduxError(action.payload);
    },
    [getPlacemarkColumnsType.fulfilled.type]: (
      state,
      action: PayloadAction<IPlacemarkColumnsType>
    ) => {
      state.getPlacemarkColumnsTypeLoading = false;
      state.getPlacemarkColumnsTypeError = false;
      state.placemarkColumnsType = action.payload;
      state.getPlacemarkColumnsTypeSuccess = true;
    },
    [getPlacemarkColumnsType.pending.type]: (state) => {
      state.getPlacemarkColumnsTypeLoading = true;
    },
    [getPlacemarkColumnsType.rejected.type]: (state, action: PayloadAction) => {
      state.getPlacemarkColumnsTypeLoading = false;
      state.getPlacemarkColumnsTypeError = true;
      handleReduxError(action.payload);
    },
    [createPlacemarkColumns.fulfilled.type]: (state) => {
      state.createPlacemarkColumnsLoading = false;
      state.createPlacemarkColumnsError = false;
      state.createPlacemarkColumnsSuccess = true;
      toast.success("Тип данных создан успешно!");
    },
    [createPlacemarkColumns.pending.type]: (state) => {
      state.createPlacemarkColumnsLoading = true;
    },
    [createPlacemarkColumns.rejected.type]: (state, action: PayloadAction) => {
      state.createPlacemarkColumnsLoading = false;
      state.createPlacemarkColumnsError = true;
      handleReduxError(action.payload);
    },
    [getSinglePlacemarkColumn.fulfilled.type]: (
      state,
      action: PayloadAction<IPlacemarkColumns>
    ) => {
      state.getSinglePlacemarkColumnLoading = false;
      state.getSinglePlacemarkColumnError = false;
      state.singlePlacemarkColumn = action.payload;
      state.getSinglePlacemarkColumnSuccess = true;
    },
    [getSinglePlacemarkColumn.pending.type]: (state) => {
      state.getSinglePlacemarkColumnLoading = true;
    },
    [getSinglePlacemarkColumn.rejected.type]: (
      state,
      action: PayloadAction
    ) => {
      state.getSinglePlacemarkColumnLoading = false;
      state.getSinglePlacemarkColumnError = true;
      handleReduxError(action.payload);
    },
    [editPlacemarkColumns.fulfilled.type]: (state) => {
      state.editPlacemarkColumnsLoading = false;
      state.editPlacemarkColumnsError = false;
      state.editPlacemarkColumnsSuccess = true;
      toast.success("Kолонок успешно отредактирован!");
    },
    [editPlacemarkColumns.pending.type]: (state) => {
      state.editPlacemarkColumnsLoading = true;
    },
    [editPlacemarkColumns.rejected.type]: (state, action: PayloadAction) => {
      state.editPlacemarkColumnsLoading = false;
      state.editPlacemarkColumnsError = true;
      handleReduxError(action.payload);
    },
    [deletePlacemarkColumns.fulfilled.type]: (state) => {
      state.deletePlacemarkColumnsLoading = false;
      state.deletePlacemarkColumnsError = false;
      state.deletePlacemarkColumnsSuccess = true;
      toast.success("Kолонок успешно удален!");
    },
    [deletePlacemarkColumns.pending.type]: (state) => {
      state.deletePlacemarkColumnsLoading = true;
    },
    [deletePlacemarkColumns.rejected.type]: (state, action: PayloadAction) => {
      state.deletePlacemarkColumnsLoading = false;
      state.deletePlacemarkColumnsError = true;
      handleReduxError(action.payload);
    },
  },
});

export const { cleanPlacemarkColumns } = placemarkColumnsSlice.actions;

export default placemarkColumnsSlice.reducer;
