import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "src/configs";
import {
  IGrids,
  IEditGrids,
  IGridsFilterMethods,
  IGridsTextFonts,
} from "src/models/IGrids";

export const getAllGrids = createAsyncThunk(
  "get-all-grids",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.get<IGrids>(`${BASE_URL}/grids`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          "filter[layer-uuid]": id,
        },
      });
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const getSingleGrid = createAsyncThunk(
  "get-single-grid",
  async (id: string | undefined, thunkAPI) => {
    try {
      const response = await axios.get<IGrids>(`${BASE_URL}/grid/${id}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const editGrids = createAsyncThunk(
  "edit-grids",
  async (data: IEditGrids, thunkAPI) => {
    try {
      const response = await axios.put<IEditGrids>(
        `${BASE_URL}/grids/${data.id}`,
        data,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const getGridsFilterMethods = createAsyncThunk(
  "get-grids-filter-method",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<IGridsFilterMethods>(
        `${BASE_URL}/grids/filter-methods`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const getGridsTextFonts = createAsyncThunk(
  "get-grids-text-fonts",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<IGridsTextFonts>(
        `${BASE_URL}/grids/text-fonts`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const getGridsGroupMethods = createAsyncThunk(
  "get-grids-group-methods",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<IGrids>(`${BASE_URL}/grids/group-methods`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);