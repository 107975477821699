import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WorkspacesState } from './workspacesInterface';
import { IWorkspace } from 'src/models/IWorkspace';
import { toast } from 'react-toastify';
import handleReduxError from "src/shared/utils/handleReduxError";

import {
  getAllWorkspaces,
  createWorkspace,
  getSingleWorkspace,
  editWorkspace,
  deleteWorkspace
} from './workspacesAction';

const initialState:WorkspacesState = {
  getAllWorkspacesLoading: false,
  getAllWorkspacesSuccess: false,
  getAllWorkspacesError: false,
  allWorkspaces: {},

  createWorkspaceLoading: false,
  createWorkspaceSuccess: false,
  createWorkspaceError: false,

  getSingleWorkspaceLoading: false,
  getSingleWorkspaceSuccess: false,
  getSingleWorkspaceError: false,
  singleWorkspace: {},

  editWorkspaceLoading: false,
  editWorkspaceSuccess: false,
  editWorkspaceError: false,

  deleteWorkspaceLoading: false,
  deleteWorkspaceSuccess: false,
  deleteWorkspaceError: false,
};


const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    cleanWorkspace: (state) => {
      state.createWorkspaceLoading=false;
      state.createWorkspaceSuccess=false;
      state.createWorkspaceError=false;

      state.editWorkspaceLoading=false;
      state.editWorkspaceSuccess=false;
      state.editWorkspaceError=false;

      state.deleteWorkspaceLoading=false;
      state.deleteWorkspaceSuccess=false;
      state.deleteWorkspaceError=false;
    },
  },
  extraReducers: {
    [getAllWorkspaces.fulfilled.type]: (state, action: PayloadAction<IWorkspace>) => {
      state.getAllWorkspacesLoading = false;
      state.getAllWorkspacesError = false;
      state.allWorkspaces = action.payload;
      state.getAllWorkspacesSuccess = true;
    },
    [getAllWorkspaces.pending.type]: (state) => {
      state.getAllWorkspacesLoading = true;
    },
    [getAllWorkspaces.rejected.type]: (state, action: PayloadAction) => {
      state.getAllWorkspacesLoading = false;
      state.getAllWorkspacesError = true;
      handleReduxError(action.payload);
    },
    [createWorkspace.fulfilled.type]: (state) => {
      state.createWorkspaceLoading = false;
      state.createWorkspaceError = false;
      state.createWorkspaceSuccess = true;
      toast.success('Рабочее пространство успешно создано!');
    },
    [createWorkspace.pending.type]: (state) => {
      state.createWorkspaceLoading = true;
    },
    [createWorkspace.rejected.type]: (state, action: PayloadAction) => {
      state.createWorkspaceLoading = false;
      state.createWorkspaceError = true;
      handleReduxError(action.payload);
    },
    [getSingleWorkspace.fulfilled.type]: (state, action: PayloadAction<IWorkspace>) => {
      state.getSingleWorkspaceLoading = false;
      state.getSingleWorkspaceError = false;
      state.singleWorkspace = action.payload;
      state.getSingleWorkspaceSuccess = true;
    },
    [getSingleWorkspace.pending.type]: (state) => {
      state.getSingleWorkspaceLoading = true;
    },
    [getSingleWorkspace.rejected.type]: (state, action: PayloadAction) => {
      state.getSingleWorkspaceLoading = false;
      state.getSingleWorkspaceError = true;
      handleReduxError(action.payload);
    },
    [editWorkspace.fulfilled.type]: (state) => {
      state.editWorkspaceLoading = false;
      state.editWorkspaceError = false;
      state.editWorkspaceSuccess = true;
      toast.success('Рабочее пространство успешно обновлено!');
    },
    [editWorkspace.pending.type]: (state) => {
      state.editWorkspaceLoading = true;
    },
    [editWorkspace.rejected.type]: (state, action: PayloadAction) => {
      state.editWorkspaceLoading = false;
      state.editWorkspaceError = true;
      handleReduxError(action.payload);
    },
    [deleteWorkspace.fulfilled.type]: (state) => {
      state.deleteWorkspaceLoading = false;
      state.deleteWorkspaceError = false;
      state.deleteWorkspaceSuccess = true;
      toast.success('Рабочее пространство успешно удалено!');
    },
    [deleteWorkspace.pending.type]: (state) => {
      state.deleteWorkspaceLoading = true;
    },
    [deleteWorkspace.rejected.type]: (state, action: PayloadAction) => {
      state.deleteWorkspaceLoading = false;
      state.deleteWorkspaceError = true;
      handleReduxError(action.payload);
    },
  }
});


export const { cleanWorkspace } = workspaceSlice.actions;

export default workspaceSlice.reducer;