import { FC } from "react";
import ReactLoading from "react-loading";

import { SpinnerOverlay, Container, LoadingBoxWrapper } from "./styles";

export const Loading: FC = (): JSX.Element => (
  <SpinnerOverlay>
    {/* @ts-ignore TODO: fix this */}
    <ReactLoading type={"balls"} color={"#5154F6"} height={67} width={35} />
  </SpinnerOverlay>
);

export const LoadingContainer: FC = (): JSX.Element => (
  <Container>
    <Loading />
  </Container>
);

export const LoadingBtn: FC = (): JSX.Element => (
  // @ts-ignore TODO: fix this
  <ReactLoading type={"balls"} color={"white"} height={30} width={27} />
);

export const LoadingItem = () => {
  return (
    <LoadingBoxWrapper>
      <div />
    </LoadingBoxWrapper>
  );
};
