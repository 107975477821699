import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
`;

export const ComponentStyled = styled.div`
  width: 300px;
  height: 400px;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-top: 20px;
  img {
    width: 100%;
    height: auto;
    margin: auto;
  }
  @media screen and (max-width: 350px) {
    width: 250px;
    height: auto;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  span {
    font-size: 18px;
    font-weight: 500;
    color: #0C60A7;
  }
  h1 {
    font-size: 15px;
    font-weight: 400;
  }
`;

export const BackButton = styled.div`
  width: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-left: 10px;
  cursor: pointer;
  a {
    font-size: 18px;
    font-weight: 500;
    color: #0C60A7;
    margin-left: 10px;
    text-decoration: none;
  }
  img {
    width: 20px;
    height: 20px;
  }
`;