import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "src/configs";
import { ICreateGraphVertexColumns, IEditGraphVertexColumns, IGraphVertexColumns } from "src/models/IGraphVertexColumns";

export const getGraphVertexColumns = createAsyncThunk(
  "get-graph-vertex-columns",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<IGraphVertexColumns>(`${BASE_URL}/graph-vertice-columns`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const getGraphVertexColumnsType = createAsyncThunk(
  "get-graph-vertex-columns-type",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<IGraphVertexColumns>(
        `${BASE_URL}/graph-vertice-columns/data-types`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const createGraphVertexColumns = createAsyncThunk(
  "create-graph-vertex-columns",
  async (formData: ICreateGraphVertexColumns, thunkAPI) => {
    try {
      const response = await axios.post<ICreateGraphVertexColumns>(
        `${BASE_URL}/graph-vertice-columns`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const getSingleGraphVertexColumn = createAsyncThunk(
  "get-single-graph-vertex-column",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.get<IGraphVertexColumns>(`${BASE_URL}/graph-vertice-columns/${id}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const editGraphVertexColumns = createAsyncThunk(
  "edit-graph-vertex-columns",
  async (formData: IEditGraphVertexColumns, thunkAPI) => {
    try {
      const response = await axios.put<IEditGraphVertexColumns>(
        `${BASE_URL}/graph-vertice-columns/${formData.id}`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const deleteGraphVertexColumns = createAsyncThunk(
  "delete-graph-vertex-columns",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.delete<IGraphVertexColumns>(
        `${BASE_URL}/GraphVertex-columns/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);
