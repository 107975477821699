import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "src/configs";
import { ICreatePolygonColumns, IEditPolygonColumns, IPolygonColumns } from 'src/models/IPolygonColumns';

export const getPolygonColumns = createAsyncThunk(
  "get-polygon-columns",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<IPolygonColumns>(`${BASE_URL}/polygon-columns`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const getPolygonColumnsType = createAsyncThunk(
  "get-polygon-columns-type",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<IPolygonColumns>(
        `${BASE_URL}/polygon-columns/data-types`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const createPolygonColumns = createAsyncThunk(
  "create-polygon-columns",
  async (formData: ICreatePolygonColumns, thunkAPI) => {
    try {
      const response = await axios.post<ICreatePolygonColumns>(
        `${BASE_URL}/polygon-columns`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const getSinglePolygonColumn = createAsyncThunk(
  "get-single-polygon-column",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.get<IPolygonColumns>(`${BASE_URL}/polygon-columns/${id}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const editPolygonColumns = createAsyncThunk(
  "edit-polygon-columns",
  async (formData: IEditPolygonColumns, thunkAPI) => {
    try {
      const response = await axios.put<IEditPolygonColumns>(
        `${BASE_URL}/polygon-columns/${formData.id}`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const deletePolygonColumns = createAsyncThunk(
  "delete-polygon-columns",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.delete<IPolygonColumns>(
        `${BASE_URL}/polygon-columns/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);
