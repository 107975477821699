import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPolygonColumnGroups } from 'src/models/IPolygonColumnGroups';
import { IPolygonColumnGroupsState } from './polygonColumnGroupsInterface';
import { toast } from 'react-toastify';
import handleReduxError from "src/shared/utils/handleReduxError";

import {
  getPolygonColumnGroups, 
  createPolygonColumnGroups,
  getSinglePolygonColumnGroup,
  editPolygonColumnGroups,
  deletePolygonColumnGroups
} from './polygonColumnGroupsAction';


const initialState:IPolygonColumnGroupsState = {
  getPolygonColumnGroupsSuccess: false,
  getPolygonColumnGroupsLoading: false,
  getPolygonColumnGroupsError: false,
  polygonColumnGroups: {},

  createPolygonColumnGroupsSuccess: false,
  createPolygonColumnGroupsLoading: false,
  createPolygonColumnGroupsError: false,

  getSinglePolygonColumnGroupSuccess: false,
  getSinglePolygonColumnGroupLoading: false,
  getSinglePolygonColumnGroupError: false,
  singlePolygonColumnGroup: {},

  editPolygonColumnGroupsSuccess: false,
  editPolygonColumnGroupsLoading: false,
  editPolygonColumnGroupsError: false,

  deletePolygonColumnGroupsSuccess: false,
  deletePolygonColumnGroupsLoading: false,
  deletePolygonColumnGroupsError: false,
};

const polygonColumnGroupSlice = createSlice({
  name: 'polygon-column-groups',
  initialState,
  reducers: {
    cleanPolygonColumnGroups: (state) => {
      state.createPolygonColumnGroupsSuccess= false;
      state.createPolygonColumnGroupsLoading= false;
      state.createPolygonColumnGroupsError= false;

      state.editPolygonColumnGroupsSuccess= false;
      state.editPolygonColumnGroupsLoading= false;
      state.editPolygonColumnGroupsError= false;

      state.deletePolygonColumnGroupsSuccess= false;
      state.deletePolygonColumnGroupsLoading= false;
      state.deletePolygonColumnGroupsError= false;
    },
  },
  extraReducers: {
    [getPolygonColumnGroups.fulfilled.type]: (state, action: PayloadAction<IPolygonColumnGroups>) => {
      state.getPolygonColumnGroupsLoading = false;
      state.getPolygonColumnGroupsError = false;
      state.polygonColumnGroups = action.payload;
      state.getPolygonColumnGroupsSuccess = true;
    },
    [getPolygonColumnGroups.pending.type]: (state) => {
      state.getPolygonColumnGroupsLoading = true;
    },
    [getPolygonColumnGroups.rejected.type]: (state, action: PayloadAction) => {
      state.getPolygonColumnGroupsLoading = false;
      state.getPolygonColumnGroupsError = true;
      handleReduxError(action.payload);
    },
    [createPolygonColumnGroups.fulfilled.type]: (state) => {
      state.createPolygonColumnGroupsLoading = false;
      state.createPolygonColumnGroupsError = false;
      state.createPolygonColumnGroupsSuccess = true;
      toast.success('Группа столбцов успешно создана!');
    },
    [createPolygonColumnGroups.pending.type]: (state) => {
      state.createPolygonColumnGroupsLoading = true;
    },
    [createPolygonColumnGroups.rejected.type]: (state, action: PayloadAction) => {
      state.createPolygonColumnGroupsLoading = false;
      state.createPolygonColumnGroupsError = true;
      handleReduxError(action.payload);
    },
    [getSinglePolygonColumnGroup.fulfilled.type]: (state, action: PayloadAction<IPolygonColumnGroups>) => {
      state.getSinglePolygonColumnGroupLoading = false;
      state.getSinglePolygonColumnGroupError = false;
      state.singlePolygonColumnGroup = action.payload;
      state.getSinglePolygonColumnGroupSuccess = true;
    },
    [getSinglePolygonColumnGroup.pending.type]: (state) => {
      state.getSinglePolygonColumnGroupLoading = true;
    },
    [getSinglePolygonColumnGroup.rejected.type]: (state, action: PayloadAction) => {
      state.getSinglePolygonColumnGroupLoading = false;
      state.getSinglePolygonColumnGroupError = true;
      handleReduxError(action.payload);
    },
    [editPolygonColumnGroups.fulfilled.type]: (state) => {
      state.editPolygonColumnGroupsLoading = false;
      state.editPolygonColumnGroupsError = false;
      state.editPolygonColumnGroupsSuccess = true;
      toast.success('Группы столбцов успешно отредактированы!');
    },
    [editPolygonColumnGroups.pending.type]: (state) => {
      state.editPolygonColumnGroupsLoading = true;
    },
    [editPolygonColumnGroups.rejected.type]: (state, action: PayloadAction) => {
      state.editPolygonColumnGroupsLoading = false;
      state.editPolygonColumnGroupsError = true;
      handleReduxError(action.payload);
    },
    [deletePolygonColumnGroups.fulfilled.type]: (state) => {
      state.deletePolygonColumnGroupsLoading = false;
      state.deletePolygonColumnGroupsError = false;
      state.deletePolygonColumnGroupsSuccess = true;
      toast.success('Группа столбцов успешно удалена!');
    },
    [deletePolygonColumnGroups.pending.type]: (state) => {
      state.deletePolygonColumnGroupsLoading = true;
    },
    [deletePolygonColumnGroups.rejected.type]: (state, action: PayloadAction) => {
      state.deletePolygonColumnGroupsLoading = false;
      state.deletePolygonColumnGroupsError = true;
      handleReduxError(action.payload);
    },
  }
});

export const { cleanPolygonColumnGroups } = polygonColumnGroupSlice.actions;

export default polygonColumnGroupSlice.reducer;