import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPlacemarkColumnGroups } from 'src/models/IPlacemarkColumnGroups';
import { IPlacemarkColumnGroupsState } from './placemarkColumnGroupsInterface';
import { toast } from 'react-toastify';
import handleReduxError from "src/shared/utils/handleReduxError";

import {
  getPlacemarkColumnGroups, 
  createPlacemarkColumnGroups,
  getSinglePlacemarkColumnGroup,
  editPlacemarkColumnGroups,
  deletePlacemarkColumnGroups
} from './placemarkColumnGroupsAction';


const initialState:IPlacemarkColumnGroupsState = {
  getPlacemarkColumnGroupsSuccess: false,
  getPlacemarkColumnGroupsLoading: false,
  getPlacemarkColumnGroupsError: false,
  placemarkColumnGroups: {},

  createPlacemarkColumnGroupsSuccess: false,
  createPlacemarkColumnGroupsLoading: false,
  createPlacemarkColumnGroupsError: false,

  getSinglePlacemarkColumnGroupSuccess: false,
  getSinglePlacemarkColumnGroupLoading: false,
  getSinglePlacemarkColumnGroupError: false,
  singlePlacemarkColumnGroup: {},

  editPlacemarkColumnGroupsSuccess: false,
  editPlacemarkColumnGroupsLoading: false,
  editPlacemarkColumnGroupsError: false,

  deletePlacemarkColumnGroupsSuccess: false,
  deletePlacemarkColumnGroupsLoading: false,
  deletePlacemarkColumnGroupsError: false,
};

const placemarkColumnGroupSlice = createSlice({
  name: 'placemarkColumnGroups',
  initialState,
  reducers: {
    cleanPlacemarkColumnGroups: (state) => {
      state.createPlacemarkColumnGroupsSuccess= false;
      state.createPlacemarkColumnGroupsLoading= false;
      state.createPlacemarkColumnGroupsError= false;

      state.editPlacemarkColumnGroupsSuccess= false;
      state.editPlacemarkColumnGroupsLoading= false;
      state.editPlacemarkColumnGroupsError= false;

      state.deletePlacemarkColumnGroupsSuccess= false;
      state.deletePlacemarkColumnGroupsLoading= false;
      state.deletePlacemarkColumnGroupsError= false;
    },
  },
  extraReducers: {
    [getPlacemarkColumnGroups.fulfilled.type]: (state, action: PayloadAction<IPlacemarkColumnGroups>) => {
      state.getPlacemarkColumnGroupsLoading = false;
      state.getPlacemarkColumnGroupsError = false;
      state.placemarkColumnGroups = action.payload;
      state.getPlacemarkColumnGroupsSuccess = true;
    },
    [getPlacemarkColumnGroups.pending.type]: (state) => {
      state.getPlacemarkColumnGroupsLoading = true;
    },
    [getPlacemarkColumnGroups.rejected.type]: (state, action: PayloadAction) => {
      state.getPlacemarkColumnGroupsLoading = false;
      state.getPlacemarkColumnGroupsError = true;
      handleReduxError(action.payload);
    },
    [createPlacemarkColumnGroups.fulfilled.type]: (state) => {
      state.createPlacemarkColumnGroupsLoading = false;
      state.createPlacemarkColumnGroupsError = false;
      state.createPlacemarkColumnGroupsSuccess = true;
      toast.success('Группа столбцов успешно создана!');
    },
    [createPlacemarkColumnGroups.pending.type]: (state) => {
      state.createPlacemarkColumnGroupsLoading = true;
    },
    [createPlacemarkColumnGroups.rejected.type]: (state, action: PayloadAction) => {
      state.createPlacemarkColumnGroupsLoading = false;
      state.createPlacemarkColumnGroupsError = true;
      handleReduxError(action.payload);
    },
    [getSinglePlacemarkColumnGroup.fulfilled.type]: (state, action: PayloadAction<IPlacemarkColumnGroups>) => {
      state.getSinglePlacemarkColumnGroupLoading = false;
      state.getSinglePlacemarkColumnGroupError = false;
      state.singlePlacemarkColumnGroup = action.payload;
      state.getSinglePlacemarkColumnGroupSuccess = true;
    },
    [getSinglePlacemarkColumnGroup.pending.type]: (state) => {
      state.getSinglePlacemarkColumnGroupLoading = true;
    },
    [getSinglePlacemarkColumnGroup.rejected.type]: (state, action: PayloadAction) => {
      state.getSinglePlacemarkColumnGroupLoading = false;
      state.getSinglePlacemarkColumnGroupError = true;
      handleReduxError(action.payload);
    },
    [editPlacemarkColumnGroups.fulfilled.type]: (state) => {
      state.editPlacemarkColumnGroupsLoading = false;
      state.editPlacemarkColumnGroupsError = false;
      state.editPlacemarkColumnGroupsSuccess = true;
      toast.success('Группы столбцов успешно отредактированы!');
    },
    [editPlacemarkColumnGroups.pending.type]: (state) => {
      state.editPlacemarkColumnGroupsLoading = true;
    },
    [editPlacemarkColumnGroups.rejected.type]: (state, action: PayloadAction) => {
      state.editPlacemarkColumnGroupsLoading = false;
      state.editPlacemarkColumnGroupsError = true;
      handleReduxError(action.payload);
    },
    [deletePlacemarkColumnGroups.fulfilled.type]: (state) => {
      state.deletePlacemarkColumnGroupsLoading = false;
      state.deletePlacemarkColumnGroupsError = false;
      state.deletePlacemarkColumnGroupsSuccess = true;
      toast.success('Группа столбцов успешно удалена!');
    },
    [deletePlacemarkColumnGroups.pending.type]: (state) => {
      state.deletePlacemarkColumnGroupsLoading = true;
    },
    [deletePlacemarkColumnGroups.rejected.type]: (state, action: PayloadAction) => {
      state.deletePlacemarkColumnGroupsLoading = false;
      state.deletePlacemarkColumnGroupsError = true;
      handleReduxError(action.payload);
    },
  }
});

export const { cleanPlacemarkColumnGroups } = placemarkColumnGroupSlice.actions;

export default placemarkColumnGroupSlice.reducer;