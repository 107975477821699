import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGraphVertexColumnGroups } from 'src/models/IGraphVertexColumnGroups';
import { IGraphVertexColumnGroupsState } from './graphVertexColumnGroupsInterface';
import { toast } from 'react-toastify';
import handleReduxError from "src/shared/utils/handleReduxError";

import {
  getGraphVertexColumnGroups, 
  createGraphVertexColumnGroups,
  getSingleGraphVertexColumnGroup,
  editGraphVertexColumnGroups,
  deleteGraphVertexColumnGroups
} from './graphVertexColumnGroupsAction';


const initialState:IGraphVertexColumnGroupsState = {
  getGraphVertexColumnGroupsSuccess: false,
  getGraphVertexColumnGroupsLoading: false,
  getGraphVertexColumnGroupsError: false,
  graphVertexColumnGroups: {},

  createGraphVertexColumnGroupsSuccess: false,
  createGraphVertexColumnGroupsLoading: false,
  createGraphVertexColumnGroupsError: false,

  getSingleGraphVertexColumnGroupSuccess: false,
  getSingleGraphVertexColumnGroupLoading: false,
  getSingleGraphVertexColumnGroupError: false,
  singleGraphVertexColumnGroup: {},

  editGraphVertexColumnGroupsSuccess: false,
  editGraphVertexColumnGroupsLoading: false,
  editGraphVertexColumnGroupsError: false,

  deleteGraphVertexColumnGroupsSuccess: false,
  deleteGraphVertexColumnGroupsLoading: false,
  deleteGraphVertexColumnGroupsError: false,
};

const graphVertexColumnGroupsSlice = createSlice({
  name: 'graph-vertex-column-groups',
  initialState,
  reducers: {
    cleanGraphVertexColumnGroups: (state) => {
      state.createGraphVertexColumnGroupsSuccess= false;
      state.createGraphVertexColumnGroupsLoading= false;
      state.createGraphVertexColumnGroupsError= false;

      state.editGraphVertexColumnGroupsSuccess= false;
      state.editGraphVertexColumnGroupsLoading= false;
      state.editGraphVertexColumnGroupsError= false;

      state.deleteGraphVertexColumnGroupsSuccess= false;
      state.deleteGraphVertexColumnGroupsLoading= false;
      state.deleteGraphVertexColumnGroupsError= false;
    },
  },
  extraReducers: {
    [getGraphVertexColumnGroups.fulfilled.type]: (state, action: PayloadAction<IGraphVertexColumnGroups>) => {
      state.getGraphVertexColumnGroupsLoading = false;
      state.getGraphVertexColumnGroupsError = false;
      state.graphVertexColumnGroups = action.payload;
      state.getGraphVertexColumnGroupsSuccess = true;
    },
    [getGraphVertexColumnGroups.pending.type]: (state) => {
      state.getGraphVertexColumnGroupsLoading = true;
    },
    [getGraphVertexColumnGroups.rejected.type]: (state, action: PayloadAction) => {
      state.getGraphVertexColumnGroupsLoading = false;
      state.getGraphVertexColumnGroupsError = true;
      handleReduxError(action.payload);
    },
    [createGraphVertexColumnGroups.fulfilled.type]: (state) => {
      state.createGraphVertexColumnGroupsLoading = false;
      state.createGraphVertexColumnGroupsError = false;
      state.createGraphVertexColumnGroupsSuccess = true;
      toast.success('Группа столбцов успешно создана!');
    },
    [createGraphVertexColumnGroups.pending.type]: (state) => {
      state.createGraphVertexColumnGroupsLoading = true;
    },
    [createGraphVertexColumnGroups.rejected.type]: (state, action: PayloadAction) => {
      state.createGraphVertexColumnGroupsLoading = false;
      state.createGraphVertexColumnGroupsError = true;
      handleReduxError(action.payload);
    },
    [getSingleGraphVertexColumnGroup.fulfilled.type]: (state, action: PayloadAction<IGraphVertexColumnGroups>) => {
      state.getSingleGraphVertexColumnGroupLoading = false;
      state.getSingleGraphVertexColumnGroupError = false;
      state.singleGraphVertexColumnGroup = action.payload;
      state.getSingleGraphVertexColumnGroupSuccess = true;
    },
    [getSingleGraphVertexColumnGroup.pending.type]: (state) => {
      state.getSingleGraphVertexColumnGroupLoading = true;
    },
    [getSingleGraphVertexColumnGroup.rejected.type]: (state, action: PayloadAction) => {
      state.getSingleGraphVertexColumnGroupLoading = false;
      state.getSingleGraphVertexColumnGroupError = true;
      handleReduxError(action.payload);
    },
    [editGraphVertexColumnGroups.fulfilled.type]: (state) => {
      state.editGraphVertexColumnGroupsLoading = false;
      state.editGraphVertexColumnGroupsError = false;
      state.editGraphVertexColumnGroupsSuccess = true;
      toast.success('Группы столбцов успешно отредактированы!');
    },
    [editGraphVertexColumnGroups.pending.type]: (state) => {
      state.editGraphVertexColumnGroupsLoading = true;
    },
    [editGraphVertexColumnGroups.rejected.type]: (state, action: PayloadAction) => {
      state.editGraphVertexColumnGroupsLoading = false;
      state.editGraphVertexColumnGroupsError = true;
      handleReduxError(action.payload);
    },
    [deleteGraphVertexColumnGroups.fulfilled.type]: (state) => {
      state.deleteGraphVertexColumnGroupsLoading = false;
      state.deleteGraphVertexColumnGroupsError = false;
      state.deleteGraphVertexColumnGroupsSuccess = true;
      toast.success('Группа столбцов успешно удалена!');
    },
    [deleteGraphVertexColumnGroups.pending.type]: (state) => {
      state.deleteGraphVertexColumnGroupsLoading = true;
    },
    [deleteGraphVertexColumnGroups.rejected.type]: (state, action: PayloadAction) => {
      state.deleteGraphVertexColumnGroupsLoading = false;
      state.deleteGraphVertexColumnGroupsError = true;
      handleReduxError(action.payload);
    },
  }
});

export const { cleanGraphVertexColumnGroups } = graphVertexColumnGroupsSlice.actions;

export default graphVertexColumnGroupsSlice.reducer;