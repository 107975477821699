import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InitialState, PlacemarkFormField } from "./types";

const initialState: InitialState = {
  formFieldList: null,
  visibleModal: false,
};

const placemarkModalSlice = createSlice({
  name: "placemarks",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    updateFormFieldList(
      state,
      action: PayloadAction<Record<string, PlacemarkFormField[]> | null>
    ) {
      state.formFieldList = action.payload;
    },
    updateVisibleModal(state, action: PayloadAction<boolean>) {
      state.visibleModal = action.payload;
    },
  },
});

export const { reset, updateFormFieldList, updateVisibleModal } =
  placemarkModalSlice.actions;

export default placemarkModalSlice.reducer;
