import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGrids, IGridsFilterMethods, IGridsTextFonts, IGridsGroupMethods } from "src/models/IGrids";
import { IGridsState } from "./gridsInterface";
import { toast } from "react-toastify";
import handleReduxError from "src/shared/utils/handleReduxError";

import { 
  getAllGrids, 
  getSingleGrid, 
  editGrids,
  getGridsFilterMethods,
  getGridsTextFonts,
  getGridsGroupMethods
} from "./gridsAction";

const initialState: IGridsState = {
  getAllGridsSuccess: false,
  getAllGridsLoading: false,
  getAllGridsError: false,
  allGrids: {},

  getSingleGridSuccess: false,
  getSingleGridLoading: false,
  getSingleGridError: false,
  singleGrid: {},

  editGridsSuccess: false,
  editGridsLoading: false,
  editGridsError: false,

  getGridsFilterMethodsLoading: false,
  getGridsFilterMethodsSuccess: false,
  getGridsFilterMethodsError: false,
  gridsFilterMethods: {},

  getGridsTextFontsLoading: false,
  getGridsTextFontsSuccess: false,
  getGridsTextFontsError: false,
  gridsTextFonts: {},

  getGridsGroupMethodsLoading: false,
  getGridsGroupMethodsSuccess: false,
  getGridsGroupMethodsError: false,
  gridsGroupMethods: {}
};

const gridsSlice = createSlice({
  name: "grids",
  initialState,
  reducers: {
    cleanGrids: (state) => {
      state.editGridsSuccess = false;
      state.editGridsLoading = false;
      state.editGridsError = false;

      state.getSingleGridLoading = false;
      state.getSingleGridError = false;
      state.getSingleGridSuccess = false;
      state.singleGrid = {};

      state.getAllGridsSuccess= false;
      state.getAllGridsLoading= false;
      state.getAllGridsError= false;
      state.allGrids= {};
    },
  },
  extraReducers: {
    [getAllGrids.fulfilled.type]: (state, action: PayloadAction<IGrids>) => {
      state.getAllGridsLoading = false;
      state.getAllGridsError = false;
      state.allGrids = action.payload;
      state.getAllGridsSuccess = true;
    },
    [getAllGrids.pending.type]: (state) => {
      state.getAllGridsLoading = true;
    },
    [getAllGrids.rejected.type]: (state, action: PayloadAction) => {
      state.getAllGridsLoading = false;
      state.getAllGridsError = true;
      handleReduxError(action.payload);
    },
    [getSingleGrid.fulfilled.type]: (state, action: PayloadAction<IGrids>) => {
      state.getSingleGridLoading = false;
      state.getSingleGridError = false;
      state.singleGrid = action.payload;
      state.getSingleGridSuccess = true;
    },
    [getSingleGrid.pending.type]: (state) => {
      state.getSingleGridLoading = true;
    },
    [getSingleGrid.rejected.type]: (state, action: PayloadAction) => {
      state.getSingleGridLoading = false;
      state.getSingleGridError = true;
      handleReduxError(action.payload);
    },
    [editGrids.fulfilled.type]: (state) => {
      state.editGridsLoading = false;
      state.editGridsError = false;
      state.editGridsSuccess = true;
      toast.success("Cеткa успешно измененa!");
    },
    [editGrids.pending.type]: (state) => {
      state.editGridsLoading = true;
    },
    [editGrids.rejected.type]: (state, action: PayloadAction) => {
      state.editGridsLoading = false;
      state.editGridsError = true;
      handleReduxError(action.payload);
    },
    [getGridsFilterMethods.fulfilled.type]: (state, action: PayloadAction<IGridsFilterMethods>) => {
      state.getGridsFilterMethodsLoading = false;
      state.getGridsFilterMethodsError = false;
      state.gridsFilterMethods = action.payload;
      state.getGridsFilterMethodsSuccess = true;
    },
    [getGridsFilterMethods.pending.type]: (state) => {
      state.getGridsFilterMethodsLoading = true;
    },
    [getGridsFilterMethods.rejected.type]: (state, action: PayloadAction) => {
      state.getGridsFilterMethodsLoading = false;
      state.getGridsFilterMethodsError = true;
      handleReduxError(action.payload);
    },
     [getGridsTextFonts.fulfilled.type]: (state, action: PayloadAction<IGridsTextFonts>) => {
      state.getGridsTextFontsLoading = false;
      state.getGridsTextFontsError = false;
      state.gridsTextFonts = action.payload;
      state.getGridsTextFontsSuccess = true;
    },
    [getGridsTextFonts.pending.type]: (state) => {
      state.getGridsTextFontsLoading = true;
    },
    [getGridsTextFonts.rejected.type]: (state, action: PayloadAction) => {
      state.getGridsTextFontsLoading = false;
      state.getGridsTextFontsError = true;
      handleReduxError(action.payload);
    },
    [getGridsGroupMethods.fulfilled.type]: (state, action: PayloadAction<IGridsGroupMethods>) => {
      state.getGridsGroupMethodsLoading = false;
      state.getGridsGroupMethodsError = false;
      state.gridsGroupMethods = action.payload;
      state.getGridsGroupMethodsSuccess = true;
    },
    [getGridsGroupMethods.pending.type]: (state) => {
      state.getGridsGroupMethodsLoading = true;
    },
    [getGridsGroupMethods.rejected.type]: (state, action: PayloadAction) => {
      state.getGridsGroupMethodsLoading = false;
      state.getGridsGroupMethodsError = true;
      handleReduxError(action.payload);
    },
  },
});

export const { cleanGrids } = gridsSlice.actions;

export default gridsSlice.reducer;