import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "src/configs";
import { IWorkspace } from "src/models/IWorkspace";
import {
  CreateWorkspacePermission,
  EditWorkspacePermission,
  WorkspacePermissionList,
} from "./workspacePermissionInterface";

export const getWorkspacePermissions = createAsyncThunk(
  "getWorkspacePermissions",
  async (queryString: any, thunkAPI) => {
    try {
      const response = await axios.get<WorkspacePermissionList[]>(
        `${BASE_URL}/workspace-permissions`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            accept: "application/json",
          },
          params: {
            ...queryString,
          },
        }
      );
      return response.data;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const createWorkspacePermissions = createAsyncThunk(
  "createWorkspacePermissions",
  async (data: CreateWorkspacePermission, thunkAPI) => {
    try {
      const response = await axios.post<any>(
        `${BASE_URL}/workspace-permissions`,
        data,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const getWorkspacePermissionsById = createAsyncThunk(
  "getWorkspacePermissionsById",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.get<WorkspacePermissionList>(
        `${BASE_URL}/workspace-permissions/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const editWorkspacePermissions = createAsyncThunk(
  "editWorkspacePermissions",
  async (data: EditWorkspacePermission, thunkAPI) => {
    try {
      const response = await axios.put<any>(
        `${BASE_URL}/workspace-permissions`,
        data,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const deleteWorkspacePermissions = createAsyncThunk(
  "deleteWorkspacePermissions",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.delete<IWorkspace>(
        `${BASE_URL}/workspace-permissions/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);
