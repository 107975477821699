import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGraphVertexColumns } from 'src/models/IGraphVertexColumns';
import { IGraphVertexColumnsState } from './graphVertexColumnsInterface';
import { toast } from 'react-toastify';
import handleReduxError from "src/shared/utils/handleReduxError";

import {
  getGraphVertexColumns, 
  createGraphVertexColumns, 
  getSingleGraphVertexColumn, 
  editGraphVertexColumns, 
  deleteGraphVertexColumns, 
  getGraphVertexColumnsType
} from './graphVertexColumnsAction';

const initialState:IGraphVertexColumnsState = {
  getGraphVertexColumnsSuccess: false,
  getGraphVertexColumnsLoading: false,
  getGraphVertexColumnsError: false,
  graphVertexColumns: {},

  getGraphVertexColumnsTypeSuccess: false,
  getGraphVertexColumnsTypeError: false,
  getGraphVertexColumnsTypeLoading: false,
  graphVertexColumnsType: {},

  createGraphVertexColumnsSuccess: false,
  createGraphVertexColumnsLoading: false,
  createGraphVertexColumnsError: false,

  getSingleGraphVertexColumnSuccess: false,
  getSingleGraphVertexColumnLoading: false,
  getSingleGraphVertexColumnError: false,
  singleGraphVertexColumn: {},

  editGraphVertexColumnsSuccess: false,
  editGraphVertexColumnsLoading: false,
  editGraphVertexColumnsError: false,

  deleteGraphVertexColumnsSuccess: false,
  deleteGraphVertexColumnsLoading: false,
  deleteGraphVertexColumnsError: false,
};

const graphVertexColumnsSlice = createSlice({
  name: 'graph-vertex-columns',
  initialState,
  reducers: {
    cleanGraphVertexColumns: (state) => {
      state.createGraphVertexColumnsSuccess= false;
      state.createGraphVertexColumnsLoading= false;
      state.createGraphVertexColumnsError= false;

      state.editGraphVertexColumnsSuccess= false;
      state.editGraphVertexColumnsLoading= false;
      state.editGraphVertexColumnsError= false;

      state.deleteGraphVertexColumnsSuccess= false;
      state.deleteGraphVertexColumnsLoading= false;
      state.deleteGraphVertexColumnsError= false;
    },
  },
  extraReducers: {
    [getGraphVertexColumns.fulfilled.type]: (state, action: PayloadAction<IGraphVertexColumns>) => {
      state.getGraphVertexColumnsLoading = false;
      state.getGraphVertexColumnsError = false;
      state.graphVertexColumns = action.payload;
      state.getGraphVertexColumnsSuccess = true;
    },
    [getGraphVertexColumns.pending.type]: (state) => {
      state.getGraphVertexColumnsLoading = true;
    },
    [getGraphVertexColumns.rejected.type]: (state, action: PayloadAction) => {
      state.getGraphVertexColumnsLoading = false;
      state.getGraphVertexColumnsError = true;
      handleReduxError(action.payload);
    },
    [getGraphVertexColumnsType.fulfilled.type]: (state, action: PayloadAction<IGraphVertexColumns>) => {
      state.getGraphVertexColumnsTypeLoading = false;
      state.getGraphVertexColumnsTypeError = false;
      state.graphVertexColumnsType = action.payload;
      state.getGraphVertexColumnsTypeSuccess = true;
    },
    [getGraphVertexColumnsType.pending.type]: (state) => {
      state.getGraphVertexColumnsTypeLoading = true;
    },
    [getGraphVertexColumnsType.rejected.type]: (state, action: PayloadAction) => {
      state.getGraphVertexColumnsTypeLoading = false;
      state.getGraphVertexColumnsTypeError = true;
      handleReduxError(action.payload);
    },
    [createGraphVertexColumns.fulfilled.type]: (state) => {
      state.createGraphVertexColumnsLoading = false;
      state.createGraphVertexColumnsError = false;
      state.createGraphVertexColumnsSuccess = true;
      toast.success('Тип данных создан успешно!');
    },
    [createGraphVertexColumns.pending.type]: (state) => {
      state.createGraphVertexColumnsLoading = true;
    },
    [createGraphVertexColumns.rejected.type]: (state, action: PayloadAction) => {
      state.createGraphVertexColumnsLoading = false;
      state.createGraphVertexColumnsError = true;
      handleReduxError(action.payload);
    },
    [getSingleGraphVertexColumn.fulfilled.type]: (state, action: PayloadAction<IGraphVertexColumns>) => {
      state.getSingleGraphVertexColumnLoading = false;
      state.getSingleGraphVertexColumnError = false;
      state.singleGraphVertexColumn = action.payload;
      state.getSingleGraphVertexColumnSuccess = true;
    },
    [getSingleGraphVertexColumn.pending.type]: (state) => {
      state.getSingleGraphVertexColumnLoading = true;
    },
    [getSingleGraphVertexColumn.rejected.type]: (state, action: PayloadAction) => {
      state.getSingleGraphVertexColumnLoading = false;
      state.getSingleGraphVertexColumnError = true;
      handleReduxError(action.payload);
    },
    [editGraphVertexColumns.fulfilled.type]: (state) => {
      state.editGraphVertexColumnsLoading = false;
      state.editGraphVertexColumnsError = false;
      state.editGraphVertexColumnsSuccess = true;
      toast.success('Kолонок успешно отредактирован!');
    },
    [editGraphVertexColumns.pending.type]: (state) => {
      state.editGraphVertexColumnsLoading = true;
    },
    [editGraphVertexColumns.rejected.type]: (state, action: PayloadAction) => {
      state.editGraphVertexColumnsLoading = false;
      state.editGraphVertexColumnsError = true;
      handleReduxError(action.payload);
    },
    [deleteGraphVertexColumns.fulfilled.type]: (state) => {
      state.deleteGraphVertexColumnsLoading = false;
      state.deleteGraphVertexColumnsError = false;
      state.deleteGraphVertexColumnsSuccess = true;
      toast.success('Kолонок успешно удален!');
    },
    [deleteGraphVertexColumns.pending.type]: (state) => {
      state.deleteGraphVertexColumnsLoading = true;
    },
    [deleteGraphVertexColumns.rejected.type]: (state, action: PayloadAction) => {
      state.deleteGraphVertexColumnsLoading = false;
      state.deleteGraphVertexColumnsError = true;
      handleReduxError(action.payload);
    },
  }
});

export const { cleanGraphVertexColumns } = graphVertexColumnsSlice.actions;

export default graphVertexColumnsSlice.reducer;