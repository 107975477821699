import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUsersRole } from "src/models/IUsersRole";
import { IUsersWorkspacePermissions } from "src/models/IUsersWorkspacePermissions";
import { IUsersLayerPermissions } from "src/models/IUsersLayerPermissions";
import { UsersRoleState } from "./userRoleInterface";
import handleReduxError from "src/shared/utils/handleReduxError";

import {
  getUsersRole,
  getUsersWorkspacePermissions,
  getUsersLayerPermissions,
} from "./usersRoleAction";

const initialState: UsersRoleState = {
  getUsersRoleLoading: false,
  getUsersRoleSuccess: false,
  getUsersRoleError: false,
  usersRole: {},

  getWorkspacePermissionsLoading: false,
  getWorkspacePermissionsSuccess: false,
  getWorkspacePermissionsError: false,
  workspacePermissions: {},

  getLayerPermissionsLoading: false,
  getLayerPermissionsSuccess: false,
  getLayerPermissionsError: false,
  LayerPermissons: {},
};

export const usersRoleSlice = createSlice({
  name: "users-role",
  initialState,
  reducers: {},
  extraReducers: {
    [getUsersRole.fulfilled.type]: (state, action: PayloadAction<IUsersRole>) => {
      state.getUsersRoleLoading = false;
      state.getUsersRoleError = false;
      state.usersRole = action.payload;
      state.getUsersRoleSuccess = true;
    },
    [getUsersRole.pending.type]: (state) => {
      state.getUsersRoleLoading = true;
    },
    [getUsersRole.rejected.type]: (state, action: PayloadAction) => {
      state.getUsersRoleLoading = false;
      state.getUsersRoleError = true;
      handleReduxError(action.payload);
    },
    [getUsersWorkspacePermissions.fulfilled.type]: (
      state,
      action: PayloadAction<IUsersWorkspacePermissions>
    ) => {
      state.getWorkspacePermissionsLoading = false;
      state.getWorkspacePermissionsError = false;
      state.workspacePermissions = action.payload;
      state.getWorkspacePermissionsSuccess = true;
    },
    [getUsersWorkspacePermissions.pending.type]: (state) => {
      state.getWorkspacePermissionsLoading = true;
    },
    [getUsersWorkspacePermissions.rejected.type]: (state, action: PayloadAction) => {
      state.getWorkspacePermissionsLoading = false;
      state.getWorkspacePermissionsError = true;
      handleReduxError(action.payload);
    },
    [getUsersLayerPermissions.fulfilled.type]: (state, action: PayloadAction<IUsersLayerPermissions>) => {
      state.getLayerPermissionsLoading = false;
      state.getLayerPermissionsError = false;
      state.LayerPermissons = action.payload;
      state.getLayerPermissionsSuccess = true;
    },
    [getUsersLayerPermissions.pending.type]: (state) => {
      state.getLayerPermissionsLoading = true;
    },
    [getUsersLayerPermissions.rejected.type]: (state, action: PayloadAction) => {
      state.getLayerPermissionsLoading = false;
      state.getLayerPermissionsError = true;
      handleReduxError(action.payload);
    },
  },
});

export default usersRoleSlice.reducer;
