import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "src/configs";
import {
  IWorkspace,
  ICreateWorkspace,
  IEditWorkspace,
} from "src/models/IWorkspace";

export const getAllWorkspaces = createAsyncThunk(
  "getAllWorkspaces",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<IWorkspace>(`${BASE_URL}/workspaces`, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
          accept: "application/json",
        },
      });
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const createWorkspace = createAsyncThunk(
  "createWorkspace",
  async (data: ICreateWorkspace, thunkAPI) => {
    try {
      const response = await axios.post<IWorkspace>(
        `${BASE_URL}/workspaces`,
        data,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const getSingleWorkspace = createAsyncThunk(
  "getSingleWorkspace",
  async (id: string | undefined | null, thunkAPI) => {
    try {
      const response = await axios.get<IWorkspace>(
        `${BASE_URL}/workspaces/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const editWorkspace = createAsyncThunk(
  "edit-workspace",
  async (formData: IEditWorkspace, thunkAPI) => {
    try {
      const response = await axios.put<IWorkspace>(
        `${BASE_URL}/workspaces/${formData.id}`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);

export const deleteWorkspace = createAsyncThunk(
  "deleteWorkspace",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.delete<IWorkspace>(
        `${BASE_URL}/workspaces/${id}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data?.errors);
    }
  }
);
